import { marked } from 'marked';
import { MIN_ROW_HEIGHT } from '../utils/constants';

const fontSize = 14;
const lineHeight = 1.5;
const paragraphOffset = 16;

/**
 * Calculates the height of a div element based on the provided markdown text.
 * @param {string} markdownText - The markdown text to calculate the height for.
 * @param {string} containerWidth - The width of the container that markdown text will be displayed in.
 * @returns {number} The calculated height of the div element.
 */
export const calculateTextHeight = (markdownText: string, containerWidth = 200) => {
  const html = marked(markdownText).toString();

  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a document
  const doc = parser.parseFromString(html, 'text/html');

  // Extract plain text from the document
  const plainText = doc.body.textContent || '';

  // Approximate width of a character in pixels (this can vary based on the font)
  const charWidth = fontSize * 0.6; // 0.6 is a typical average width-to-height ratio for characters

  // Calculate the maximum number of characters per line based on the container width
  const charsPerLine = Math.floor(containerWidth / charWidth);

  // Split the text into lines based on line breaks
  const lines = plainText.split('\n');

  // Calculate the number of lines considering the characters per line
  let totalLines = 0;
  for (let line of lines) {
    // Remove extra spaces and count the number of characters
    line = line.trim();
    if (line) {
      // Calculate the number of lines this line will occupy
      const numLines = Math.ceil(line.length / charsPerLine);
      totalLines += numLines;
    }
  }

  // Calculate the number of paragraphs
  const paragraphs = doc.body.querySelectorAll('p').length;

  // Calculate the height of the div
  const textHeight = totalLines * lineHeight * fontSize;
  const marginHeight = paragraphs * paragraphOffset;
  const divHeight = textHeight + marginHeight;
  return divHeight > MIN_ROW_HEIGHT ? divHeight : MIN_ROW_HEIGHT;
};
