import { IconMenu } from '@unique/icons';
import {
  FC,
  MouseEvent,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useContext,
  CSSProperties,
} from 'react';
import cn from 'classnames';
import { ButtonIcon, ButtonSize, ButtonVariant, Spinner, SpinnerTheme, useOutsideClick } from '..';

import ReactDOM from 'react-dom';
import { LayoutContext } from '@unique/shared-library';

const PopupMenuPortal = ({ children }: { children: ReactNode }) => {
  return ReactDOM.createPortal(children, document.body);
};

export type PopupMenuItem = {
  label: string;
  icon?: ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

type PopupMenuProps = {
  menuItems: PopupMenuItem[];
  buttonClassesClosed?: string;
  buttonClassesOpened?: string;
  buttonVariant?: ButtonVariant;
  buttonIcon?: ReactNode;
  position?: 'right' | 'left';
};

const popUpMenuWidth = 130;
const popUpContainerWidth = 30;

export const PopupMenu: FC<PopupMenuProps> = ({
  menuItems,
  buttonClassesClosed = '',
  buttonClassesOpened = '',
  buttonVariant = ButtonVariant.PRIMARY,
  buttonIcon,
  position = 'right',
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const { secondLevelNavContainerId } = useContext(LayoutContext);
  const popUpMenuWrapperRef = useRef<HTMLDivElement | null>(null);
  const [positionStyle, setPositionStyle] = useState<CSSProperties>({});

  /**
   * When  menu is open we want to prevent scrolling on the second level nav container
   * to make sure it stays aligned with the selected item
   */
  useEffect(() => {
    const scrollContainer = document.getElementById(secondLevelNavContainerId);
    if (!scrollContainer) return;
    scrollContainer.style.overflowY = showMenu ? 'hidden' : 'auto';
    // When popupMenu is unmount we restore scrolling
    return () => {
      scrollContainer.style.overflowY = 'auto';
    };
  }, [showMenu]);

  const toggleMenu: MouseEventHandler<HTMLButtonElement> = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setShowMenu(!showMenu);
  };

  const positionPopupMenu = () => {
    if (!popUpMenuWrapperRef?.current) return;
    const rect = popUpMenuWrapperRef.current.getBoundingClientRect();

    const popUpMenuRect = menuRef?.current?.getBoundingClientRect() ?? {
      height: 50,
      width: popUpMenuWidth,
    };

    let style: CSSProperties = {};

    if (position === 'left') {
      style = {
        top: rect.top,
        left: rect.left - (popUpMenuRect.width + 30),
      };
    }

    if (position === 'right') {
      style = {
        top: rect.top,
        left: rect.right,
      };
    }

    // window height
    if (window.innerHeight - rect.top < popUpMenuRect.height) {
      style.top = window.innerHeight - popUpMenuRect.height - 10;
    }

    setPositionStyle(style);
  };

  const handleClickOutsideDropdown = () => {
    setShowMenu(false);
  };

  useOutsideClick(menuRef, handleClickOutsideDropdown);

  useEffect(() => {
    if (showMenu) {
      positionPopupMenu();
    }
  }, [showMenu]);

  return (
    <div className={`relative w-[${popUpContainerWidth}px]`} ref={popUpMenuWrapperRef}>
      <ButtonIcon
        icon={buttonIcon ?? <IconMenu />}
        variant={buttonVariant}
        buttonSize={ButtonSize.SMALL}
        onClick={toggleMenu}
        className={cn({
          [buttonClassesClosed]: !showMenu,
          [buttonClassesOpened]: showMenu,
        })}
      ></ButtonIcon>
      {showMenu && (
        <PopupMenuPortal>
          <div
            className={`bg-secondary text-on-secondary absolute top-0 z-10 ml-5 min-w-[${popUpMenuWidth}px] rounded-[8px] py-2`}
            ref={menuRef}
            style={positionStyle}
          >
            {menuItems.map(({ label, icon, isLoading, onClick, disabled }) => {
              return (
                <button
                  key={label}
                  className={cn("subtitle-2 text-on-secondary hover:bg-secondary-variant flex w-full items-center gap-x-2 px-4 py-2.5 transition disabled:opacity-60", {
                    "cursor-not-allowed": isLoading || disabled,
                  })}
                  onClick={(event) => {
                    onClick?.(event);
                    setShowMenu(false);
                  }}
                  disabled={isLoading || disabled}
                >
                  {isLoading ? (
                    <Spinner theme={SpinnerTheme.LIGHT} size={18} wrapperClasses="mt-1" />
                  ) : (
                    icon
                  )}
                  <span>{label}</span>
                </button>
              );
            })}
          </div>
        </PopupMenuPortal>
      )}
    </div>
  );
};
