import { cloneElement, isValidElement, ReactNode } from 'react';
import { TextHighlighter } from '../src/TextHighlighter';
import { uniqueId } from 'lodash';

/**
 * Recursively applies text highlighting to a React node or its children
 */
export const applyTextHighlighterToChild = (
  child: ReactNode,
  textToHighlight: string,
): ReactNode => {
  if (typeof child === 'string') {
    return <TextHighlighter textToHighlight={child} searchWords={[textToHighlight]} />;
  }

  if (Array.isArray(child)) {
    return child.map((item) => (
      <span key={uniqueId('highlight-item-')}>
        {applyTextHighlighterToChild(item, textToHighlight)}
      </span>
    ));
  }

  if (isValidElement(child)) {
    const childProps = { ...child.props };

    if (childProps.children) {
      childProps.children = applyTextHighlighterToChild(childProps.children, textToHighlight);
    }
    return cloneElement(child, childProps);
  }

  return child;
};

/**
 * Applies text highlighting to React children elements
 * @param children - The React children to process (can be a single child or array of children)
 * @param textToHighlight - The text string to search for and highlight
 * @returns A new React node or array of nodes with highlighting applied where matches are found
 */
export const applyTextHighlighter = (children: ReactNode, textToHighlight: string): ReactNode => {
  if (!textToHighlight) return children;
  return (
    <>
      {Array.isArray(children)
        ? children.map((child) => (
            <span key={uniqueId('highlight-wrapper-')}>
              {applyTextHighlighterToChild(child, textToHighlight)}
            </span>
          ))
        : applyTextHighlighterToChild(children, textToHighlight)}
    </>
  );
};
