import { useRef, useEffect, useState } from 'react';
const THRESHOLD = 0;

export enum ScrollDirection {
  Up = 'UP',
  Down = 'DOWN',
}
export const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState(ScrollDirection.Up);

  const blocking = useRef(false);

  // Used to compare with current scroll position and define scroll direction
  const prevScrollY = useRef(0);

  // Keep scrollHeight to reset previous scroll position if scrollHeight changes during scroll
  const prevScrollHeight = useRef(0);

  useEffect(() => {
    // add the option to define a custom wrapper (this is for example needed on pages that are wrapped with a scrollbar class)
    const scrollbarWrapper = document.getElementById('scrollbar-wrapper');
    prevScrollY.current = scrollbarWrapper ? scrollbarWrapper.scrollTop : window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = scrollbarWrapper ? scrollbarWrapper.scrollTop : window.scrollY;

      // Exit early if scroll position hasn't changed
      if (scrollY === prevScrollY.current) {
        blocking.current = false;
        return;
      }

      // If during scroll height changes, we need to reset the previous scroll position
      // otherwise might trigger a false positive scroll direction detection
      if (prevScrollHeight.current != scrollbarWrapper?.scrollHeight) {
        prevScrollHeight.current = scrollbarWrapper?.scrollHeight ?? 0;
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      if (Math.abs(scrollY - prevScrollY.current) >= THRESHOLD) {
        const newScrollDirection =
          scrollY > prevScrollY.current ? ScrollDirection.Down : ScrollDirection.Up;

        setScrollDirection(newScrollDirection);
        prevScrollY.current = scrollY > 0 ? scrollY : 0;
      }

      blocking.current = false;
    };

    const onScroll = () => {
      if (!blocking.current) {
        blocking.current = true;
        window.requestAnimationFrame(updateScrollDirection);
      }
    };

    // IMPORTANT: LOST DAYS ON TRYING TO HANDLE SCROLL EVENT INSTEAD
    // Scroll event is triggered by CSS resizing on DOM events.
    // Using wheel and touch instead, easier, works great.
    if (scrollbarWrapper) {
      // Add event listener to scrolbar height change
      // to avoid detecting scroll direction in scrollListener
      // when scrollHeight change (eg. pagination in infiniteScroll)
      scrollbarWrapper.addEventListener('wheel', onScroll, { passive: true });
      scrollbarWrapper.addEventListener('touchmove', onScroll, { passive: true });
    } else {
      window.addEventListener('wheel', onScroll, { passive: true });
      window.addEventListener('touchmove', onScroll, { passive: true });
    }

    return () => {
      if (scrollbarWrapper) {
        scrollbarWrapper.removeEventListener('wheel', onScroll);
        scrollbarWrapper.removeEventListener('touchmove', onScroll);
      } else {
        window.removeEventListener('wheel', onScroll);
        window.removeEventListener('touchmove', onScroll);
      }
    };
  }, []);

  return { scrollDirection, setScrollDirection };
};
