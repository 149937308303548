import { IconArrowDown } from '@unique/icons';
import { LayoutContext, useScreens } from '@unique/shared-library';
import cn from 'classnames';
import { FC, ReactNode, useContext } from 'react';

export enum ExploreMenuButtonVariant {
  LIGHT = 'light',
  DARK = 'dark',
}

type ExploreMenuButtonProps = {
  variant?: ExploreMenuButtonVariant;
  onClick?: () => void;
  icon: ReactNode;
  label: string;
};
export const ExploreMenuButton: FC<ExploreMenuButtonProps> = ({
  variant = ExploreMenuButtonVariant.LIGHT,
  onClick,
  icon,
  label,
}) => {
  const { setIsMenuExpanded } = useContext(LayoutContext);
  const { isMobile } = useScreens();
  const handleClickExploreSpaces = () => {
    if (isMobile) {
      setIsMenuExpanded(false);
    }
    onClick?.();
  };

  return (
    <button
      onClick={handleClickExploreSpaces}
      className={cn({
        'subtitle-2 flex w-full items-center gap-x-2 rounded-[8px] border px-4 py-2 transition':
          true,
        'text-on-secondary border-[#fff] border-opacity-30 opacity-80 hover:border-opacity-100 hover:opacity-100':
          variant === ExploreMenuButtonVariant.LIGHT,
        'border-control text-on-surface hover:border-on-surface':
          variant === ExploreMenuButtonVariant.DARK,
      })}
    >
      {icon}
      {label}
      <IconArrowDown className="ml-auto origin-center -rotate-90" />
    </button>
  );
};
