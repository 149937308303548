import { useOutsideClick } from '@unique/component-library';
import { IconCheckmark, IconCloseSlim, IconFilter } from '@unique/icons';
import cn from 'classnames';
import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import Select, { ActionMeta, ClassNamesConfig, GroupBase } from 'react-select';

type FilterOption = { label: string; value: string };
export type FilterProps = {
  id: string;
  options: FilterOption[];
  name: string;
  disabled?: boolean;
  multiple?: boolean;
  showSearch?: boolean;
  onUpdateFilter?: (selectedOption: string | string[] | undefined) => void;
  selectedOptions?: FilterOption[];
  handleClickFilter?: () => void;
  buttonClasses?: string;
};

export const Filter: FC<FilterProps> = ({
  id,
  options,
  name,
  disabled,
  multiple,
  showSearch,
  onUpdateFilter,
  selectedOptions,
  handleClickFilter,
  buttonClasses = '',
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [selectedValue, setSelectedValue] = useState<FilterOption | null>();
  const [selectedValues, setSelectedValues] = useState<FilterOption[] | null>([]);
  const popupRef = useRef(null);

  useEffect(() => {
    if (!selectedOptions?.length) {
      setSelectedValue(null);
      setSelectedValues(null);
    }
  }, [selectedOptions]);

  const customClassNames: ClassNamesConfig<FilterOption, boolean, GroupBase<FilterOption>> = {
    control: (state) =>
      cn({
        '!outline-none !body-2 !h-10 !rounded-md !bg-surface !text-on-background-main m-2 !tex-sm':
          true,
        '!border-primary-cta !shadow-none': state.isFocused,
        '!border-control': !state.isFocused,
        '!opacity-0 !h-0 !min-h-0 !m-0 !p-0 !max-h-0': !showSearch,
      }),
    option: (state) =>
      cn({
        '!text-sm !py-2': true,
        '!bg-background-variant !text-on-background-main': state.isFocused,
        '!bg-control !text-on-surface': state.isSelected,
        '!bg-surface !text-on-surface': !state.isFocused && !state.isSelected,
      }),
    singleValue: () => '!text-sm !text-on-background-main',
    input: () => '!text-on-background-main !text-sm',
    valueContainer: () => '!px-2 !text-on-background-main',
    menu: () => '!border-transparent !py-0 !relative !shadow-none !my-0',
    menuList: () => '!p-0 !max-h-[200px] !bg-surface',
    indicatorSeparator: () => '!hidden',
    dropdownIndicator: () => '!text-on-background-main !hidden',
    placeholder: () => '!text-on-control-dimmed !text-sm',
    noOptionsMessage: () => '!text-on-control-dimmed !text-sm !py-10 italic !bg-surface',
    indicatorsContainer: () => '!hidden',
  };

  const closePopup = () => {
    setShowOptions(false);
  };

  useOutsideClick(popupRef, closePopup);

  const handleSelectOption = (option: FilterOption | undefined) => {
    if (multiple) {
      const newOptions = selectedValues ?? [];
      if (option) {
        newOptions.push(option);
      }
      setSelectedValues(newOptions);
      onUpdateFilter?.(newOptions.map((option) => option.value));
    } else {
      setSelectedValue(option);
      onUpdateFilter?.(option?.value);
    }
  };
  const handleUnselectOption = (option: FilterOption | undefined) => {
    if (multiple) {
      const newOptions = selectedValues?.filter((value) => value.value !== option?.value) ?? [];
      setSelectedValues(newOptions);
      onUpdateFilter?.(newOptions.map((option) => option.value));
    } else {
      setSelectedValue(null);
      onUpdateFilter?.(undefined);
    }
  };

  const handleChange = (actionMeta: ActionMeta<FilterOption>) => {
    const { option, action } = actionMeta;
    setShowOptions(false);
    if (action === 'select-option') {
      handleSelectOption(option);
    }

    if (action === 'deselect-option') {
      handleUnselectOption(option);
    }
  };

  const handleClearFilters = () => {
    setShowOptions(false);
    setSelectedValue(null);
    setSelectedValues(null);
    onUpdateFilter?.(undefined);
  };

  const onClickFilter = (event: MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLElement;
    if (target.dataset.clearFilters) return;
    setShowOptions(!showOptions);
  };

  const sortBySelected = (options: FilterOption[]) => {
    return options.sort((a, b) => {
      if (!selectedOptions) return 0;
      if (selectedOptions.some((selectedOption) => selectedOption.value === a.value)) return -1;
      if (selectedOptions.some((selectedOption) => selectedOption.value === b.value)) return 1;
      return 0;
    });
  };

  return (
    <div className="relative">
      <button
        title={name}
        disabled={disabled}
        className={cn({
          'body-2 bg-surface text-on-surface hover:bg-background-variant group relative flex min-h-[40px] cursor-pointer items-center gap-x-3 whitespace-nowrap rounded-bl rounded-br-2xl rounded-tl-2xl rounded-tr px-3 py-2 text-left transition-all':
            true,
          '!cursor-not-allowed': disabled,
          'pointer-events-none': !options?.length,
          '!bg-background-variant': showOptions,
          [buttonClasses]: buttonClasses,
        })}
        onClick={onClickFilter}
      >
        <span className="text-primary-cta pointer-events-none">
          <IconFilter width="16px" height="16px" />
        </span>
        <span className="truncate">
          {selectedOptions?.length
            ? selectedOptions.map((option) => option.label).join(', ')
            : name}
        </span>
        {selectedOptions && selectedOptions?.length > 0 && (
          <span
            onClick={handleClearFilters}
            className="text-on-control-dimmed group-hover:text-on-surface"
            data-clear-filters
          >
            <IconCloseSlim width="18px" height="18px" className="pointer-events-none" />
          </span>
        )}
      </button>

      {showOptions && (
        <div
          className="bg-surface absolute left-0 top-full z-10 mt-1 h-max min-w-[180px] overflow-hidden rounded-lg shadow-xl"
          ref={popupRef}
        >
          <Select
            autoFocus
            options={sortBySelected(options)}
            backspaceRemovesValue={false}
            controlShouldRenderValue={false}
            hideSelectedOptions={false}
            isClearable={false}
            isOptionSelected={(option) => {
              return !!selectedOptions?.some(
                (selectedOption) => selectedOption.value === option.value,
              );
            }}
            menuIsOpen
            id={id}
            placeholder="Search"
            classNames={customClassNames}
            onChange={(_, actionMeta) => handleChange(actionMeta)}
            ref={popupRef}
            onMenuOpen={handleClickFilter ? handleClickFilter : undefined}
            tabSelectsValue={false}
            value={multiple ? selectedValues : selectedValue}
            isMulti
            formatOptionLabel={(option) => {
              const isSelected = selectedOptions?.some(
                (selectedOption) => selectedOption.value === option.value,
              );
              return (
                <div className="flex justify-between">
                  {option.label}
                  {isSelected && (
                    <IconCheckmark
                      className="text-primary-cta h-[24px]"
                      width="12px"
                      height="12px"
                    />
                  )}
                </div>
              );
            }}
          />
        </div>
      )}
    </div>
  );
};
