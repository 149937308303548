import {
  Badge,
  duration,
  MarkdownPreview,
  TextHighlighter,
  Tooltip,
  TooltipSize,
} from '@unique/component-library';
import { FC, ReactNode, ChangeEvent } from 'react';
import { format } from 'date-fns';
import { useScreens } from '@unique/shared-library';
import cn from 'classnames';
import IndeterminateCheckbox from '@unique/component-library/src/IndeterminateCheckbox';

export enum ListItemCardTagType {
  DATE = 'DATE',
  STRING = 'STRING',
}

interface Tag {
  icon: ReactNode;
  label?: string;
  type: ListItemCardTagType;
  value: string;
}

interface ListItemCardProps {
  description: string;
  isSelected?: boolean;
  onClickCard?: () => void;
  onSelectCard?: (e: ChangeEvent<HTMLInputElement>) => void;
  searchTerm: string;
  tags: Tag[];
  title: string;
}

export const ListItemCard: FC<ListItemCardProps> = ({
  description,
  onClickCard,
  onSelectCard,
  searchTerm,
  tags,
  title,
  isSelected = false,
}) => {
  const { isMobile } = useScreens();

  return (
    <div className="group relative cursor-pointer px-6 py-7" onClick={onClickCard}>
      <div
        className={cn({
          'border-background-variant group-hover:border-primary-cta pointer-events-none absolute bottom-0 left-0 right-0 top-0 overflow-hidden rounded-[8px] border transition-all duration-300 group-hover:shadow-md':
            true,
          'border-primary-cta shadow-md': isSelected,
        })}
      >
        <div
          className={cn({
            'h-full w-full opacity-0 transition-opacity duration-300': true,
            'bg-primary-cta opacity-20': isSelected,
          })}
        />
      </div>
      <div className="border-background-variant flex items-start gap-x-3">
        {onSelectCard && (
          <IndeterminateCheckbox
            onChange={onSelectCard}
            checked={isSelected}
            className="mt-0.5 h-5 w-5 before:absolute before:left-1 before:top-5 before:h-16 before:w-8 before:content-['']"
            preventEventBubbling
          />
        )}
        <div className="text-on-surface w-full min-w-0">
          <div className="subtitle-1 min-w-0 max-w-full truncate font-bold">
            <TextHighlighter searchWords={[searchTerm]} textToHighlight={title} />
          </div>
          {description && (
            <div className="body-2 -mx-2 mt-1 line-clamp-3">
              <MarkdownPreview
                text={description}
                enableLatex
                codeBlockClassName="max-h-[150px] overflow-y-auto"
                textToHighlight={searchTerm}
                className="last-of-type:mb-0"
              ></MarkdownPreview>
            </div>
          )}
          <div className="mt-4 flex w-full flex-wrap items-center gap-2 md:gap-x-5 lg:mt-6">
            {tags?.map((tag) => {
              if (!tag.value) return null;

              if (tag.type === ListItemCardTagType.STRING) {
                return (
                  <Badge key={tag.value} variant="outlineInfo" size="large" icon={tag.icon}>
                    {tag.value}
                  </Badge>
                );
              }

              return (
                <Tooltip
                  key={tag.value}
                  position="bottom"
                  wrapper={
                    <Badge variant="outlineInfo" size="large" icon={tag.icon}>
                      {isMobile ? '' : tag.label} {duration(new Date(tag.value))} ago
                    </Badge>
                  }
                  size={TooltipSize.SMALL}
                  arrowPosition="none"
                >
                  {format(new Date(tag.value), "MMM d, yyyy 'at' HH:mm")}
                </Tooltip>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
