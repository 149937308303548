import { useSWRConfig } from 'swr';

export const useInvalidateCache = () => {
  const { cache, mutate } = useSWRConfig();

  const invalidateCache = (partialKey: string | string[]) => {
    if (!('keys' in cache)) {
      return;
    }
    const keysToMatch = Array.isArray(partialKey) ? partialKey : [partialKey];
    Array.from(cache.keys()).forEach((key) => {
      if (!keysToMatch.some((p) => key.includes(p))) {
        return;
      }

      mutate(key);
    });
  };

  return { invalidateCache };
};
