import { FC, PropsWithChildren, ReactNode } from 'react';

const VARIANT_STYLES = {
  success: 'bg-success-dark text-on-success-dark',
  successLight: 'bg-success-light text-on-success-light',
  warning: 'bg-attention text-on-attention',
  info: 'bg-background-variant text-on-background-dimmed',
  error: 'bg-error-light text-on-error-light',
  primary: 'bg-primary-cta text-on-primary',
  outlineOnPrimary: 'border border-on-primary text-on-primary',
  outline: 'border border-attention-variant text-attention-variant',
  outlineInfo:
    'border border-control text-on-background-dimmed group-hover/button:text-on-background-main group-hover/button:border-on-background-main',
};

const SIZE_STYLES = {
  small: 'h-[14px] min-w-[14px] text-[10px] leading-[14px] px-1 rounded-[6px]',
  medium: 'h-[20px] min-w-[16px] text-xs leading-[20px] px-1.5 rounded-[6px]',
  large: 'h-[24px] min-w-[24px] body-2 leading-[24px] !font-medium px-2 rounded-[8px]',
};

export type BadgeVariant = keyof typeof VARIANT_STYLES;
export type BadgeSize = keyof typeof SIZE_STYLES;

type BadgeProps = {
  variant?: BadgeVariant;
  size?: BadgeSize;
  icon?: ReactNode;
};

const Badge: FC<PropsWithChildren<BadgeProps>> = ({
  children,
  variant = 'info',
  size = 'medium',
  icon,
}) => {
  return (
    <div
      className={`flex items-center justify-center gap-x-2 whitespace-nowrap text-center font-bold transition ${VARIANT_STYLES[variant]} ${SIZE_STYLES[size]}`}
    >
      {icon && <div className="shrink-0">{icon}</div>}
      <div className="truncate">{children}</div>
    </div>
  );
};

export default Badge;
export { Badge };
