import cn from 'classnames';
import { forwardRef, useEffect, useMemo } from 'react';
import { FileRejection, FileWithPath, useDropzone, Accept } from 'react-dropzone';

type DropzoneProps = {
  additonalClassname?: string;
  onDrop: (acceptedFiles: FileWithPath[], fileRejections: FileRejection[]) => void;
  onError: (error: Error) => void;
  accept: Accept;
  wrapperClassname?: string;
  maxFiles?: number;
  maxSize?: number;
  children?: React.ReactNode;
  disableClick?: boolean;
  setFileInput?: (fileInput: HTMLInputElement) => void;
};

export const Dropzone = forwardRef<HTMLElement, DropzoneProps>((props, ref) => {
  const {
    additonalClassname,
    onDrop,
    onError,
    accept,
    wrapperClassname,
    maxFiles = 50,
    maxSize,
    children,
    disableClick,
    setFileInput,
  } = props;

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFocused,
    inputRef,
  } = useDropzone({
    accept,
    maxFiles,
    maxSize,
    onDrop,
    onError,
    multiple: maxFiles > 1,
    noClick: disableClick,
  });

  useEffect(() => {
    if (!inputRef?.current) return;
    setFileInput?.(inputRef.current);
  }, [inputRef]);

  const className = useMemo(
    () => ({
      ...(isFocused ? { '!bg-surface !text-on-secondary': true } : {}),
      ...(isDragActive ? { '!bg-secondary-variant !text-black': true } : {}),
      ...(isDragAccept ? { '!bg-secondary-variant !text-black': true } : {}),
      ...(isDragActive ? { '!bg-surface !text-black': true } : {}),
      ...(isDragAccept ? { '!bg-success-dark !text-black': true } : {}),
      ...(isDragReject ? { '!bg-surface !text-on-secondary': true } : {}),

      ...(additonalClassname ? { [additonalClassname]: true } : {}),
    }),
    [isFocused, isDragActive, isDragAccept, isDragReject, additonalClassname],
  );

  return (
    <section
      ref={ref}
      className={cn('dropzone-container mb-4 h-full px-0 xl:mb-0', wrapperClassname)}
    >
      <div
        {...getRootProps({
          className: cn(className),
        })}
      >
        <input {...getInputProps()} id="dropzone" />
        {children}
      </div>
    </section>
  );
});

Dropzone.displayName = 'Dropzone';
