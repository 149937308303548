import { IconCopy } from '@unique/icons';
import cn from 'classnames';
import 'katex/dist/katex.min.css';
import { Highlight, themes } from 'prism-react-renderer';
import { FC } from 'react';
import Markdown, {
  Components,
  defaultUrlTransform,
  ExtraProps,
  UrlTransform,
} from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import remarkGfm from 'remark-gfm';
import { ButtonIcon, ButtonSize, ButtonVariant } from '..';
import { markdownSanitizeSchema } from '../helpers/markdownSanitizeSchema';
import { DefaultMarkdownLink } from './DefaultMarkdownLink';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { applyLatexFormat } from '../helpers/markdownLatex';
import { applyTextHighlighter } from '../helpers/applyTextHighlighter';

const TABLE_ITEM_CLASSNAME = 'border border-[1px] border-white border-opacity-50';
const TABLE_CELL_CLASSNAME = 'px-2 py-2';

type MarkdownPreviewProps = {
  text: string;
  className?: string;
  handleSelectPrompt?: (prompt: string) => void;
  copyCode?: (text: string) => void;
  copiedCode?: string;
  customComponents?: Components;
  wrapCode?: boolean;
  enableLatex?: boolean;
  codeBlockClassName?: string;
  textToHighlight?: string;
  advancedFormatting?: Record<
    string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { component: React.ComponentType<any>; props: Record<string, any> }
  >;
};

const urlTransform: UrlTransform = (url) => {
  if (url.startsWith('unique://content/')) {
    return url;
  }
  return defaultUrlTransform(url);
};

/**
 * This component should be memoized in the parent component and manually implemented if needed.
 * This was moved out to the parent component so we easily can handle async loading of data as memoDeps.
 */
export const MarkdownPreview: FC<MarkdownPreviewProps> = ({
  text,
  className = '',
  copyCode,
  copiedCode,
  customComponents,
  wrapCode = false,
  enableLatex = false,
  codeBlockClassName = '',
  textToHighlight,
  advancedFormatting,
}) => {
  const MarkdownHeading = (props: JSX.IntrinsicElements['h1']): JSX.Element => (
    <h1 className={`title-s mb-1.5 flex items-center gap-x-2 font-semibold ${props.className}`}>
      {props.children}
    </h1>
  );

  const MarkdownHeading2 = (props: JSX.IntrinsicElements['h2']): JSX.Element => (
    <h2 className={`subtitle-1 mb-2 ${props.className}`}>{props.children}</h2>
  );

  const MarkdownCode = (props: JSX.IntrinsicElements['code'] & ExtraProps): JSX.Element => {
    const { children, node } = props;
    const inline = node?.position?.start?.line === node?.position?.end?.line;
    const lang = props.className?.replace('language-', '');

    if (inline) {
      return (
        <code
          className={cn({
            'body-2 bg-secondary text-on-secondary hover:bg-secondary-variant whitespace-normal rounded-lg px-1.5 py-1 transition':
              true,
            [className]: true,
          })}
        >
          {children}
        </code>
      );
    }

    const code = children?.toString() || '';

    // Handle advanced formatting components loading
    if (lang && advancedFormatting?.[lang]) {
      try {
        const Component = advancedFormatting?.[lang]?.component;
        if (!Component) throw new Error('Component not found');
        const props = advancedFormatting?.[lang]?.props || {};
        return <Component {...props} data={code} />;
      } catch (e) {
        console.error(`Failed to parse ${lang} data:`, e);
        return <div className="text-error p-4">Invalid {lang} data</div>;
      }
    }

    return (
      <Highlight theme={themes.vsDark} code={code} language={lang || 'tsx'}>
        {({ className, style, tokens, getLineProps, getTokenProps }) => (
          <div style={style} className={`${className} mb-4 rounded-lg p-4`}>
            <pre
              className={cn({
                'whitespace-pre-wrap': wrapCode,
                'global-scrollbar overflow-x-auto': !wrapCode,
                [codeBlockClassName]: true,
              })}
            >
              {tokens.map((line, i) => (
                <div key={i} {...getLineProps({ line })} className={className}>
                  {line.map((token, key) => (
                    <span key={key} {...getTokenProps({ token })} />
                  ))}
                </div>
              ))}
            </pre>
            {copyCode && (
              <ButtonIcon
                icon={<IconCopy />}
                className="!ml-auto !flex"
                onClick={() => {
                  copyCode(code);
                }}
                variant={ButtonVariant.SECONDARY}
                buttonSize={ButtonSize.SMALL}
              >
                {`${copiedCode === code ? 'Copied' : 'Copy'}`}
              </ButtonIcon>
            )}
          </div>
        )}
      </Highlight>
    );
  };

  const MarkdownParagraph = (props: JSX.IntrinsicElements['p']): JSX.Element => (
    <p className={`mb-4 px-2 ${props.className} ${className}`}>
      {textToHighlight ? applyTextHighlighter(props.children, textToHighlight) : props.children}
    </p>
  );

  const MarkdownUnorderedList = (props: JSX.IntrinsicElements['ul']): JSX.Element => (
    <ul className={`mb-4 ml-6 list-disc px-2 ${props.className} ${className}`}>
      {textToHighlight ? applyTextHighlighter(props.children, textToHighlight) : props.children}
    </ul>
  );

  const MarkdownOrderedList = (props: JSX.IntrinsicElements['ol']): JSX.Element => (
    <ol className={`mb-4 ml-6 list-decimal px-2 ${props.className} ${className}`}>
      {textToHighlight ? applyTextHighlighter(props.children, textToHighlight) : props.children}
    </ol>
  );

  const MarkdownSuperscript = (props: JSX.IntrinsicElements['sup']): JSX.Element => (
    <sup
      className={`subtitle-2 bg-attention-variant text-on-attention-variant top-auto mr-1 inline-flex h-4 w-4 items-center justify-center rounded-md px-2 align-text-top leading-4 first-of-type:ml-1 last-of-type:mr-0 ${props.className} ${className}`}
    >
      {textToHighlight ? applyTextHighlighter(props.children, textToHighlight) : props.children}
    </sup>
  );

  const MarkdownTable = (props: JSX.IntrinsicElements['table']): JSX.Element => (
    <div className="overflow-x-auto px-2">
      <table
        className={`markdown-table my-5 w-full border-collapse ${props.className} ${className}`}
      >
        {props.children}
      </table>
    </div>
  );

  const MarkdownTableHead = (props: JSX.IntrinsicElements['thead']): JSX.Element => (
    <thead className={cn(TABLE_ITEM_CLASSNAME, props.className, className)}>{props.children}</thead>
  );

  const MarkdownTableHeadCell = (props: JSX.IntrinsicElements['th']): JSX.Element => (
    <th
      className={cn(
        TABLE_ITEM_CLASSNAME,
        TABLE_CELL_CLASSNAME,
        'text-left',
        props.className,
        className,
      )}
    >
      {props.children}
    </th>
  );

  const MarkdownTableRow = (props: JSX.IntrinsicElements['tr']): JSX.Element => (
    <tr className={cn(TABLE_ITEM_CLASSNAME, className)}>{props.children}</tr>
  );

  const MarkdownTableCell = (props: JSX.IntrinsicElements['td']): JSX.Element => (
    <td className={cn(TABLE_ITEM_CLASSNAME, TABLE_CELL_CLASSNAME, className)}>{props.children}</td>
  );

  const MarkdownLink = (props: JSX.IntrinsicElements['a']): JSX.Element => (
    <DefaultMarkdownLink href={props.href} target={props.target}>
      {props.children}
    </DefaultMarkdownLink>
  );

  const MarkdownHorizontalRule = (props: JSX.IntrinsicElements['hr']): JSX.Element => (
    <hr className={`my-5 ${props.className} ${className}`} />
  );

  const MarkdownImage = (props: JSX.IntrinsicElements['img']): JSX.Element => {
    const { src, alt } = props;
    return <img src={src} alt={alt} className="max-h-[400px] max-w-[850px]" />;
  };

  const HtmlHighlight = (props: JSX.IntrinsicElements['mark']): JSX.Element => (
    <mark
      className={`body-1 bg-info text-on-info rounded-md p-1 px-2 font-semibold ${props.className} ${className}`}
    >
      {props.children}
    </mark>
  );

  const remarkPlugins = [[remarkGfm, {}]];
  const rehypePlugins = [rehypeRaw, [rehypeSanitize, markdownSanitizeSchema]];

  if (enableLatex) {
    text = applyLatexFormat(text);
    remarkPlugins.push([remarkMath, { singleDollarTextMath: true }]);

    // https://github.com/orgs/rehypejs/discussions/63
    // @ts-expect-error - the actual code should just work but the types depend on a new major of
    // unified (which is already released but isn't in react-markdown yet)
    rehypePlugins.push(rehypeKatex);
  }
  return (
    <Markdown
      className="break-words"
      // https://github.com/orgs/rehypejs/discussions/63
      // @ts-expect-error - the actual code should just work but the types depend on a new major of
      // unified (which is already released but isn't in react-markdown yet)
      remarkPlugins={remarkPlugins}
      // https://github.com/orgs/rehypejs/discussions/63
      // @ts-expect-error - the actual code should just work but the types depend on a new major of
      // unified (which is already released but isn't in react-markdown yet)
      rehypePlugins={rehypePlugins}
      urlTransform={urlTransform}
      components={{
        h1: MarkdownHeading,
        h2: MarkdownHeading2,
        p: MarkdownParagraph,
        ul: MarkdownUnorderedList,
        ol: MarkdownOrderedList,
        sup: MarkdownSuperscript,
        table: MarkdownTable,
        thead: MarkdownTableHead,
        th: MarkdownTableHeadCell,
        tr: MarkdownTableRow,
        td: MarkdownTableCell,
        a: MarkdownLink,
        img: MarkdownImage,
        hr: MarkdownHorizontalRule,
        mark: HtmlHighlight,
        code: MarkdownCode,
        ...customComponents,
      }}
    >
      {text}
    </Markdown>
  );
};
MarkdownPreview.displayName = 'MarkdownPreview';
