export * from './calculateRowHeight';
export * from './calculateTextHeight';
export * from './exportAsExcel';
export * from './extractFileNameFromReference';
export * from './getCellId';
export * from './getColumnIndex';
export * from './getTableData';
export * from './getRowHeight';
export * from './cellEditRequestValues';
export * from './getLastOccupiedRow';
export * from './getLastOccupiedColumn';
export * from './generateEmptyLoadingRows';
export * from './generateEmptyRows';
export * from './generateEmptyColumns';
export * from './generateExcelHeaders';
export * from './getExcelColumnName';
export * from './getRowClass';
export * from './getRowHeightByDomElement';
export * from './handleAfterGuiDetached';
export * from './handleRowResize';
export * from './highlightHeaderColumnCells';
export * from './onRowDataUpdated';
export * from './removeHighlights';
export * from './transformMagicTable';
