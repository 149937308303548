import { Column, IRowNode } from 'ag-grid-enterprise';
import { getCellId } from './getCellId';

import { MIN_ROW_HEIGHT } from '../utils/constants';

export const getRowHeightByDomElement = (rowNode: IRowNode, allColumns: Column[]) => {
  // Only update the row heights if a column was actually resized by the user
  const hasData = Object.values(rowNode.data).some(
    (value) => value !== null && value !== undefined && value !== '',
  );
  if (rowNode?.rowIndex === null || rowNode?.rowIndex === undefined || !hasData) return;
  let maxHeight = MIN_ROW_HEIGHT;
  // Iterate over each column. Why?
  // The allColumns array from agGridRef.current.columnApi.getColumns() contains info about columns (IDs, fields, etc.),
  // but not about which row each column belongs to, as columns are shared across all rows.
  allColumns.forEach((column) => {
    const colId = column.getColId();
    if (!rowNode?.id || !rowNode.data[colId]) return;
    const cellId = getCellId(rowNode.id, colId);
    const cellElement = document.getElementById(cellId);
    // Update the maximum height for the row
    if (cellElement && cellElement.offsetHeight > maxHeight) {
      maxHeight = cellElement.offsetHeight;
    }
  });
  return maxHeight;
};
