import { FC } from 'react';
import { IconProps } from '.';

export const IconBolt: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.22085 11.1096L10.9222 7.35194H8.51631L10.2046 1.63376H5.68834V8.16882H8.22085V11.1096ZM12.7583 7.63785L7.69324 14.7243C7.60883 14.8468 7.49979 14.9285 7.36613 14.9694C7.23247 15.0102 7.10232 15.0102 6.9757 14.9694C6.84907 14.9285 6.74355 14.857 6.65913 14.7549C6.57472 14.6528 6.53251 14.5269 6.53251 14.3771V9.80259H5.68834C5.22405 9.80259 4.82658 9.64261 4.49595 9.32267C4.16532 9.00272 4 8.61811 4 8.16882V1.63376C4 1.18448 4.16532 0.799864 4.49595 0.479918C4.82658 0.159973 5.22405 0 5.68834 0H10.6267C11.077 0 11.4392 0.170184 11.7136 0.510551C11.988 0.850919 12.0689 1.22532 11.9563 1.63376L10.7534 5.71818H11.703C12.2095 5.71818 12.5859 5.93601 12.8321 6.37168C13.0783 6.80735 13.0537 7.22941 12.7583 7.63785Z"
        fill="currentColor"
      />
    </svg>
  );
};
