import { ReactNode, useRef, useState } from 'react';
import { IconClose } from '@unique/icons';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import cn from 'classnames';
type Props = {
  value: string;
  setValue: (value: string) => void;
  config: BoxInputEnum;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  showClearButton?: boolean;
  className?: string;
  actions?: ReactNode;
  loader?: ReactNode;
  onClearButtonClicked?: () => void;
};

export enum BoxInputEnum {
  MUTABLE = 'MUTABLE',
  IMMUTABLE = 'IMMUTABLE',
}

export const TextBox = ({
  value,
  setValue,
  config,
  showClearButton = true,
  className,
  actions,
  loader,
  placeholder,
  onChange,
  onClearButtonClicked,
}: Props) => {
  const BoxInputConfig = {
    MUTABLE: {
      props: {
        disabled: false,
        autoFocus: true,
      },
      className: 'border-0 bg-surface',
    },
    IMMUTABLE: {
      props: {
        disabled: true,
        autoFocus: false,
      },
      className: 'bg-background border-0',
    },
  };

  const CONFIG = BoxInputConfig[config as keyof typeof BoxInputConfig];
  const [isFocused, setIsFocused] = useState(false);

  const textBoxRef = useRef<HTMLTextAreaElement>(null);
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  const clearInput = () => {
    onClearButtonClicked?.();
    setValue('');
    textBoxRef.current?.focus();
  };

  return (
    <div
      className={cn(
        'border-control relative flex h-full flex-col overflow-hidden rounded-[8px] border',
        {
          'border-primary-cta': isFocused,
          'border-control': !isFocused,
        },
      )}
    >
      <textarea
        className={`placeholder:text-on-background-dimmed text-on-background-main w-full flex-1 px-5 resize-none rounded-[8px] pb-12 pt-5 ${className} ${CONFIG.className}`}
        {...CONFIG.props}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        ref={textBoxRef}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      {loader && <div className="absolute left-2 top-2">{loader}</div>}
      {!!value && showClearButton && (
        <div className="absolute right-1 top-1.5">
          <ButtonIcon
            variant={ButtonVariant.SHAPE}
            onClick={clearInput}
            className="hover:text-on-surface text-on-surface !h-4 !cursor-pointer !p-0"
          >
            <div className="bg-surface rounded-full">
              <IconClose width="16px" height="16px" />
            </div>
          </ButtonIcon>
        </div>
      )}
      <div
        className={cn('hidden w-full items-center justify-end px-2 py-2 sm:flex', {
          'bg-background': config === BoxInputEnum.IMMUTABLE,
        })}
      >
        {actions}
      </div>
    </div>
  );
};
