'use client';
import { useEffect } from 'react';

export const useKeyPress = (handler: (event: KeyboardEvent) => void) => {
  useEffect(() => {
    document.addEventListener('keydown', handler, false);
    return () => {
      document.removeEventListener('keydown', handler, false);
    };
  }, [handler]);
};
