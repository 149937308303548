import { ColDef } from 'ag-grid-enterprise';
import { TOTAL_COLUMNS } from '../utils/constants';
import { AgGridReact } from 'ag-grid-react';
import lodash from 'lodash';

/**
 * Export the table data as an Excel file
 * @param fileName - The name of the file to export
 */
export const exportAsExcel = (agGridRef: React.RefObject<AgGridReact>, fileName: string) => {
  agGridRef.current?.api.exportDataAsExcel({
    fileName: fileName,
    skipColumnHeaders: true,
    shouldRowBeSkipped: (params) => {
      const countEmptyStrings = lodash.filter(
        Object.values(params.node.data),
        (value) => value === '',
      ).length;
      return countEmptyStrings === TOTAL_COLUMNS - 1;
    },
    columnKeys: agGridRef.current?.api
      ?.getColumnDefs()
      ?.slice(1)
      ?.map((col) => (col as ColDef).field)
      ?.filter((field): field is string => field !== undefined),
  });
};
