'use client';

import { GraphQLClient } from 'graphql-request';
import { Client as WsClient } from 'graphql-ws';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { logger } from '../logger';
import { createWsClient } from './create-ws-client';

interface ClientContextProps {
  clients: Record<string, GraphQLClient>;
  websocketClients: Record<string, WsClient | null>;
  defaultClient: GraphQLClient | null;
  defaultWebsocketClient: WsClient | null;
  isReady: boolean;
  basePath: string;
  services: Record<ServiceName, ServiceUrl>;
}

type ServiceName = string;
type ServiceUrl = string;

interface ClientProviderProps {
  children: ReactNode;
  services: Record<ServiceName, ServiceUrl>;
  defaultService: ServiceName;
  basePath: string;
}

const log = logger.child({
  package: 'next-commons',
  namespace: 'swr:client-provider',
});

export const ClientContext = createContext<ClientContextProps | null>(null);

// This is a temproary hack to access ClientProvider values outside of a component with redux
export let clientContextValues: ClientContextProps | null = null;

export const ClientProvider = ({
  children,
  services,
  defaultService,
  basePath,
}: ClientProviderProps) => {
  const auth = useAuth();
  const [clients, setClients] = useState<Record<ServiceName, GraphQLClient>>({});
  const [websocketClients, setWebsocketClients] = useState<Record<ServiceName, WsClient | null>>(
    {},
  );
  const [defaultClient, setDefaultClient] = useState<GraphQLClient | null>(null);
  const [defaultWebsocketClient, setDefaultWebsocketClient] = useState<WsClient | null>(null);

  useEffect(() => {
    if (!auth?.isAuthenticated || !auth?.user?.access_token) return;

    const newClients: Record<ServiceName, GraphQLClient> = {};
    const newWebsocketClients: Record<ServiceName, WsClient | null> = {};

    for (const service in services) {
      log.info({ service }, 'Setup clients for service');
      newClients[service] = new GraphQLClient(`${services[service]}/graphql`, {
        headers: {
          Authorization: `Bearer ${auth.user.access_token}`,
        },
      });
      newWebsocketClients[service] = createWsClient({
        serviceUrl: services[service],
        service,
        accessToken: auth.user.access_token,
      });

      if (service === defaultService) {
        setDefaultClient(newClients[defaultService]);
        setDefaultWebsocketClient(newWebsocketClients[defaultService]);
      }
    }

    setClients(newClients);
    setWebsocketClients(newWebsocketClients);
  }, [services, defaultService, auth]);

  useEffect(() => {
    return () => {
      Object.values(websocketClients).forEach((client) => client?.dispose());
    };
  }, [websocketClients]);

  const isReady =
    Object.keys(clients).length === Object.keys(services).length &&
    Object.keys(websocketClients).length === Object.keys(services).length;

  // TODO see why we need this together with Seb
  // eslint-disable-next-line react-compiler/react-compiler
  clientContextValues = {
    clients,
    websocketClients,
    defaultClient,
    defaultWebsocketClient,
    isReady,
    basePath,
    services,
  };

  return <ClientContext.Provider value={clientContextValues}>{children}</ClientContext.Provider>;
};
