import { ProgressBar, ProgressBarVariant, Spinner } from '@unique/component-library';
import {
  IconBotError,
  IconBotGlasses,
  IconBotSleeping,
  IconCheckmark,
  IconClose,
} from '@unique/icons';
import { FileForUpload } from '@unique/shared-library';
import cn from 'classnames';
import Image from 'next/image';
import { FC, useState } from 'react';

interface FileUploadProps {
  files: FileForUpload[];
  basePath: string;
  className?: string;
  title?: string;
}

interface FileUpload {
  file: FileForUpload;
  basePath: string;
  className?: string;
  handleClickHideFailedFile: (fileName: string, uploadedAt: number) => void;
}

const FileUpload: FC<FileUpload> = ({
  file,
  basePath,
  className = '',
  handleClickHideFailedFile,
}) => {
  const { fileName, progress, error } = file;
  const progressBarVariant = error
    ? ProgressBarVariant.ERROR
    : progress === 100
      ? ProgressBarVariant.SUCCESS
      : ProgressBarVariant.DEFAULT;

  return (
    <div
      className={cn(
        'bg-surface text-on-background-main flex items-center gap-4 rounded-lg px-3 py-2.5 shadow-lg',
        className,
      )}
    >
      <div className="relative h-[48px] w-[48px]">
        {error && <IconBotError width="48" height="48" />}
        {!error && progress === 0 && <IconBotSleeping width="48" height="48" />}
        {!error && progress > 0 && progress < 100 && (
          <Image
            alt="Uploading"
            src={`${basePath}/static/images/bot-processing.gif`}
            priority={true}
            width={48}
            height={48}
          />
        )}
        {!error && progress === 100 && <IconBotGlasses width="48" height="48" />}
      </div>
      <div className="flex flex-1 flex-col gap-2">
        <div className="flex justify-between text-left">
          <span className="subtitle-2">{fileName}</span>
          {error && <span className="subtitle-2 text-error-dark">{error}</span>}
        </div>
        <div className="flex items-center gap-4">
          <ProgressBar progress={progress} variant={progressBarVariant} title={error} />
        </div>
      </div>
      {error && (
        <div
          className="flex h-[40px] w-[40px] cursor-pointer items-center justify-center"
          onClick={() => handleClickHideFailedFile(file.fileName, file.uploadedAt)}
        >
          <IconClose height="18px" width="18px" />
        </div>
      )}
      {!error && progress < 100 && (
        <Spinner wrapperClasses="flex h-[40px] w-[40px] items-center justify-center" />
      )}
      {!error && progress === 100 && (
        <div className="text-success-dark flex h-[40px] w-[40px] items-center justify-center">
          <IconCheckmark width="15px" height="15px" />
        </div>
      )}
    </div>
  );
};

export const FileUploadList: FC<FileUploadProps> = ({ files, className = '', title, basePath }) => {
  const [hiddenFailedFiles, setHiddenFailedFiles] = useState<string[]>([]);

  const getFileWithDateSuffix = (fileName: string, uploadedAt: number) =>
    `${fileName}-${uploadedAt}`;

  // user can hide files where the upload failed. In order to have a unique identifier
  // for each file, a combination of fileName and upload date is used
  const hideFailedFile = (fileName: string, uploadedAt: number) => {
    setHiddenFailedFiles([...hiddenFailedFiles, getFileWithDateSuffix(fileName, uploadedAt)]);
  };

  const filesToShow = files.filter(
    (file) =>
      !(
        hiddenFailedFiles.includes(getFileWithDateSuffix(file.fileName, file.uploadedAt)) &&
        file.error
      ),
  );
  if (!filesToShow.length) return null;

  return (
    <>
      {title && <div className="title-s text-on-surface my-2.5 text-left">{title}</div>}
      {filesToShow.map((file) => (
        <FileUpload
          key={file.fileName}
          file={file}
          className={className}
          handleClickHideFailedFile={hideFailedFile}
          basePath={basePath}
        />
      ))}
    </>
  );
};
