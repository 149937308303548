import { FC } from 'react';
import Highlighter from 'react-highlight-words';
import { getCSSVariableValue } from '../helpers/getCSSVariableValue';

type TextHighlighterProps = {
  searchWords: string[];
  textToHighlight: string;
};

export const TextHighlighter: FC<TextHighlighterProps> = ({ textToHighlight, searchWords }) => {
  // Append '20' to hex color to achieve 20% opacity
  // Note: Tailwind bg opacity classes can't be used since they don't work with hex color variables
  const hexHighlightColorWithOpacity = `${getCSSVariableValue('--color-info')}40`;
  return (
    <Highlighter
      searchWords={searchWords}
      textToHighlight={textToHighlight}
      highlightClassName="rounded-[4px] text-on-surface"
      highlightStyle={{ backgroundColor: hexHighlightColorWithOpacity }}
    />
  );
};
