import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';

import type { IAfterGuiAttachedParams, IDoesFilterPassParams } from 'ag-grid-enterprise';
import type { CustomFilterProps } from 'ag-grid-react';
import { useGridFilter } from 'ag-grid-react';
import { InputField } from '../../..';
import { IconCloseSlim } from '@unique/icons';

export const PartialMatchFilter = ({
  model,
  onModelChange,
  getValue,
  api,
  colDef,
}: CustomFilterProps) => {
  const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
  const [inputValue, setInputValue] = useState(model?.value || '');

  const filterContainerRef = useRef<HTMLDivElement>(null);

  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams) => {
      // Skip pinned row
      if (node.id === '1') return true;

      const cellValue = getValue(node);
      if (!cellValue) return false;

      const value = cellValue.toString().toLowerCase().replace(/[,.]/g, '');
      const filterValue = model?.value?.toLowerCase() || '';

      // If no filter value, show all rows
      if (!filterValue) return true;

      return filterValue
        .split(' ')
        .filter(Boolean)
        .every((filterWord: string) => value.includes(filterWord));
    },
    [model?.value, getValue],
  );

  const afterGuiDetached = useCallback(() => {
    const filterModel = api.getFilterModel();
    const filterField = colDef.field as string;
    if (!filterModel[filterField]?.value && !filterModel[filterField]?.filterType) {
      api.destroyFilter(filterField);
    }
  }, [api, colDef.field]);

  const afterGuiAttached = ({ hidePopup }: IAfterGuiAttachedParams) => {
    setCloseFilter(() => hidePopup);
  };

  useGridFilter({
    doesFilterPass,
    afterGuiDetached,
    afterGuiAttached,
  });

  useEffect(() => {
    const handleCloseFilter = (e: KeyboardEvent) => {
      if (!filterContainerRef.current) return;
      if (e.key === 'Escape' || e.key === 'Enter') {
        if (!closeFilter) return;
        closeFilter();
      }
    };
    // Because the filter is rendered in a portal, we need to listen to the keydown event on the document
    document?.addEventListener('keydown', handleCloseFilter);
    return () => {
      document?.removeEventListener('keydown', handleCloseFilter);
    };
  }, [closeFilter]);

  const handleChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setInputValue(value);
    onModelChange({ value });
  };

  const handleClear = () => {
    setInputValue('');
    onModelChange({ value: '' });
  };

  return (
    <div
      className="align-items-center flex w-full flex-col gap-2 rounded-md p-3"
      ref={filterContainerRef}
    >
      <label htmlFor="partialMatchFilter" className="text-on-surface">
        Filter:
      </label>
      <div className="relative w-full">
        <InputField
          id="partialMatchFilter"
          name="partialMatchFilter"
          value={inputValue}
          onChange={handleChange}
          className="border-control focus:border-primary-cta text-on-surface bg-surface w-full rounded-md font-normal"
          autoFocus
          placeholder="Type to filter"
          aria-label="Filter input"
        />
        {inputValue && (
          <button
            onClick={handleClear}
            className="text-on-control-dimmed hover:text-on-surface absolute right-2 top-1/2 -translate-y-1/2"
            aria-label="Clear filter"
          >
            <IconCloseSlim width="18px" height="18px" />
          </button>
        )}
      </div>
    </div>
  );
};
