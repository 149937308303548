import { User } from 'oidc-client-ts';
import { Theme } from '../@generated/graphql';
import { fallbackThemeColors } from '../constants/fallback-theme-colors';
import { DEFAULT_SUPPORT_EMAIL } from '../constants/default-support-email';
import { logger } from '@unique/next-commons/logger';

const THEME_LOCAL_STORAGE_KEY_PREFIX = 'uniqueStaticTheme';

export const buildDefaultTheme = () => {
  const defaultTheme: Partial<Theme> = {};
  defaultTheme.colors = fallbackThemeColors;
  defaultTheme.fontFamilies = [{ name: 'Inter', family: 'sans-serif', id: 'inter' }];
  return defaultTheme;
};

const log = logger.child({ package: 'shared-library', namespace: 'helpers:theme' });

export const parseStaticThemeColors = (staticThemeColors: string) => {
  try {
    const res: { [key: string]: string } = JSON.parse(staticThemeColors);
    return Object.entries(res).map(([key, value]) => ({
      hexValue: value,
      name: key,
      id: '',
    }));
  } catch {
    log.error('Error parsing staticThemeColors');
    return [];
  }
};

export const getThemeContextValues = (theme: Partial<Theme> | undefined, version?: string) => {
  let supportEmail = '';
  if (!theme?.settings?.hideSupportEmail) {
    supportEmail = theme?.supportEmail || DEFAULT_SUPPORT_EMAIL;
  }

  return {
    logoHeader: theme?.logoHeader || '',
    logoNavbar: theme?.logoNavbar || '',
    supportEmail,
    helpCenterLink: theme?.helpCenterLink || '',
    settings: theme?.settings || {},
    version,
  };
};

export const getThemeStyles = (theme: Partial<Theme> | undefined) => {
  return `
    :root {
      ${theme && theme.colors?.map((color) => `--${color.name}: ${color.hexValue};`).join('\n')}
    }
    ${
      theme &&
      theme.fontFamilies &&
      theme.fontFamilies
        .map(
          (fontFamily) => `.${fontFamily.name} {
        font-family: ${fontFamily.family};
      }`,
        )
        .join('\n')
    }
    ${
      theme &&
      theme.fonts &&
      theme.fonts
        .map(
          (font, i) => `@font-face {
        font-family: '${font.family}';
        src: ${
          font.files &&
          font.files
            .map((file) =>
              font.fileTypes ? `url('data:application/${font.fileTypes[i]};base64,${file}')` : '',
            )
            .join(',')
        };
        font-weight: ${font.weight};
        font-style: ${font.style};
      }`,
        )
        .join('\n')
    }
  `;
};

const getCompanyId = (user: User | undefined) => {
  if (!user) return null;
  const companyId = user?.profile['urn:zitadel:iam:user:resourceowner:id'];
  return typeof companyId === 'string' ? companyId : null;
};

const getThemeLocalStorageKey = (companyId: string) =>
  `${THEME_LOCAL_STORAGE_KEY_PREFIX}-${companyId}`;

export const getThemeFromLocalStorage = (user: User | null | undefined) => {
  if (!user) return;
  const companyId = getCompanyId(user);
  if (!companyId) return;
  return localStorage?.getItem(getThemeLocalStorageKey(companyId));
};

export const setThemeInLocalStorage = (user: User | null | undefined, theme: Partial<Theme>) => {
  if (!theme || !user) return;
  const companyId = getCompanyId(user);
  if (!companyId) return;
  const themeWithTimestamp = {
    ...theme,
    timestamp: new Date(),
  };
  localStorage?.setItem(getThemeLocalStorageKey(companyId), JSON.stringify(themeWithTimestamp));
};
