import { AgGridReact } from 'ag-grid-react';

export const getLastOccupiedRow = (agGridRef: React.RefObject<AgGridReact>) => {
  let lastEmptyRow = '1';
  let found = false;
  agGridRef.current?.api.forEachNode((node) => {
    const newNode = { ...node.data };

    // remove default row values
    delete newNode.id;
    delete newNode.rowHeight;

    if (Object.values(newNode).every((value) => !value)) {
      if (!found && node.rowIndex) {
        found = true;
        lastEmptyRow = node.data.id;
      }
    }
  });
  return Number(lastEmptyRow) - 1;
};
