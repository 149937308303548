import { HTMLProps, useEffect, useRef, MouseEvent } from 'react';
import cn from 'classnames';

function IndeterminateCheckbox({
  indeterminate,
  className = '',
  preventEventBubbling = false,
  ...rest
}: { indeterminate?: boolean; preventEventBubbling?: boolean } & HTMLProps<HTMLInputElement>) {
  if (rest.label) {
    return (
      <div className="flex items-center leading-4">
        <Checkbox
          className={className}
          indeterminate={indeterminate}
          preventEventBubbling={preventEventBubbling}
          {...rest}
        />
        <label
          className="body-2 text-on-background-main ml-3 cursor-pointer font-semibold"
          htmlFor={rest.id ?? rest.label}
        >
          {rest.label}
        </label>
      </div>
    );
  }

  return (
    <Checkbox
      className={className}
      indeterminate={indeterminate}
      preventEventBubbling={preventEventBubbling}
      {...rest}
    />
  );
}

interface CheckboxProps extends HTMLProps<HTMLInputElement> {
  indeterminate?: boolean;
  preventEventBubbling?: boolean;
  className: string;
}

const Checkbox = ({ indeterminate, className, preventEventBubbling, ...rest }: CheckboxProps) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (indeterminate !== undefined && ref.current) {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  const handleClick = (e: MouseEvent) => {
    // Prevent click events from bubbling up to parent elements.
    // This is important when the checkbox is inside clickable containers (e.g. table rows, list items)
    // to ensure only the checkbox state changes without triggering the parent's click handlers.
    preventEventBubbling && e.stopPropagation();
  };

  return (
    <input
      type="checkbox"
      ref={ref}
      disabled={rest.disabled}
      data-is-checkbox
      className={cn({
        'border-control bg-surface checked:bg-primary-cta checked:text-on-primary indeterminate:bg-primary-cta indeterminate:text-on-primary disabled:hover:!border-control hover:border-on-control-dimmed hover:checked:bg-primary-cta hover:checked:text-on-primary hover:indeterminate:bg-primary-cta hover:indeterminate:text-on-primary focus:checked:bg-primary-cta focus:checked:text-on-primary group-hover:border-on-control-dimmed disabled:!bg-control cursor-pointer rounded-[3px] disabled:cursor-not-allowed':
          true,
        [className]: true,
      })}
      onClick={handleClick}
      {...rest}
    />
  );
};
export default IndeterminateCheckbox;
