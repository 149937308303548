import { FC } from 'react';
import { IconProps } from '.';

export const IconTime: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Icon (18px)/Time">
        <path
          id="Vector"
          d="M7.992 0C3.576 0 0 3.584 0 8C0 12.416 3.576 16 7.992 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 7.992 0ZM8 14.4C4.464 14.4 1.6 11.536 1.6 8C1.6 4.464 4.464 1.6 8 1.6C11.536 1.6 14.4 4.464 14.4 8C14.4 11.536 11.536 14.4 8 14.4ZM7.824 4H7.776C7.456 4 7.2 4.256 7.2 4.576V8.352C7.2 8.632 7.344 8.896 7.592 9.04L10.912 11.032C11.184 11.192 11.536 11.112 11.696 10.84C11.864 10.568 11.776 10.208 11.496 10.048L8.4 8.208V4.576C8.4 4.256 8.144 4 7.824 4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
