import { FC } from 'react';
import { IconClose, IconLoading } from '@unique/icons';
import { IconSearch } from '@unique/icons';
import { FilterProps, InputField } from '@unique/component-library';
import { ListControlFilters } from './ListControlFilters';
import { ListControlFiltersMobile } from './ListControlFiltersMobile';

interface ListControlsProps {
  isLoading: boolean;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  onClearSearch: () => void;
  filterOptions: FilterProps[];
  onFilterUpdate: (filterId: string, selectedOption?: string | string[]) => void;
  numberOfFiltersApplied: number;
}

export const ListControls: FC<ListControlsProps> = ({
  onSearch,
  searchValue,
  isLoading,
  onClearSearch,
  filterOptions,
  onFilterUpdate,
  numberOfFiltersApplied,
}) => {
  return (
    <div className="bg-background">
      <div className="mx-auto flex max-w-[928px] flex-wrap gap-x-5 gap-y-5 px-4 py-4 md:items-center md:py-8">
        <div className="relative flex-1 md:max-w-[330px]">
          <div className="text-primary-cta absolute left-0 top-0 flex h-10 items-center pl-3">
            <IconSearch width="14px" height="14px" />
          </div>
          <InputField
            name="search"
            placeholder="Search your Chats"
            classes="!font-normal pl-9 pr-8"
            handleChange={(e) => onSearch(e)}
            value={searchValue}
            inputWrapperClasses="w-full"
          />
          {searchValue.length > 0 && (
            <div
              className="text-on-background-main absolute right-0 top-0 flex h-10 cursor-pointer items-center pr-3"
              onClick={isLoading ? undefined : onClearSearch}
            >
              {isLoading ? (
                <div className="mr-4 flex h-10 items-center">
                  <IconLoading width="17px" height="17px" />
                </div>
              ) : (
                <IconClose />
              )}
            </div>
          )}
        </div>

        <div className="self-center">
          <div className="hidden flex-1 items-center gap-x-5 gap-y-3 md:flex">
            <ListControlFilters filterOptions={filterOptions} onFilterUpdate={onFilterUpdate} />
          </div>
          <ListControlFiltersMobile
            numberOfFiltersApplied={numberOfFiltersApplied}
            onFilterUpdate={onFilterUpdate}
            filterOptions={filterOptions}
          />
        </div>
      </div>
    </div>
  );
};
