import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { EditorState } from 'lexical';

export const OnChangePlugin = ({ onChange }: { onChange: (editorState: EditorState) => void }) => {
  // Access the editor through the LexicalComposerContext
  const [editor] = useLexicalComposerContext();

  // Wrap our listener in useEffect to handle the teardown and avoid stale references.
  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      onChange(editorState);
    });
  }, [editor, onChange]);

  return null;
};
