export const getExcelColumnName = (index: number): string => {
  let columnName = '';
  let remainder: number;

  while (index >= 0) {
    remainder = index % 26;
    columnName = String.fromCharCode(65 + remainder) + columnName;
    index = Math.floor(index / 26) - 1;
  }

  return columnName;
};
