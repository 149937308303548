import { useAuth } from 'react-oidc-context';
import { extractRoles } from './extract-roles';
import { hasAnyRequiredRoles } from './required-roles';
import { Roles } from './roles';

export function useRoles() {
  const { user } = useAuth();
  const roles = user ? extractRoles(user) ?? [] : [];

  // Helper constants
  const allowChatAccess = hasAnyRequiredRoles(roles, [Roles.CHAT_BASIC]);
  const allowAdminAccess = hasAnyRequiredRoles(roles, [Roles.ADMIN_ALL]);
  const allowUnlimitedChatInput = hasAnyRequiredRoles(roles, [Roles.CHAT_UNLIMITED]);
  const allowKnowledgeBaseAccess = hasAnyRequiredRoles(roles, [
    Roles.KNOWLEDGE_READ,
    Roles.KNOWLEDGE_WRITE,
  ]);
  const allowKnowledgeBaseRead = hasAnyRequiredRoles(roles, [Roles.KNOWLEDGE_READ]);
  const allowKnowledgeBaseWrite = hasAnyRequiredRoles(roles, [Roles.KNOWLEDGE_WRITE]);
  const allowDebugRead = hasAnyRequiredRoles(roles, [Roles.DEBUG_READ]);
  const allowFeedbackAccess = hasAnyRequiredRoles(roles, [
    Roles.FEEDBACK_READ,
    Roles.CHAT_DATA_ADMIN,
  ]);
  const allowUserManagementAccess = hasAnyRequiredRoles(roles, [Roles.ADMIN_USER_MANAGEMENT_WRITE]);
  const allowSpaceAdminAccess = hasAnyRequiredRoles(roles, [Roles.ADMIN_SPACE_WRITE]);
  const allowAppRepositoryAccess = hasAnyRequiredRoles(roles, [Roles.ADMIN_APP_REPOSITORY_WRITE]);

  return {
    roles,
    allowChatAccess,
    allowUnlimitedChatInput,
    allowKnowledgeBaseAccess,
    allowKnowledgeBaseRead,
    allowKnowledgeBaseWrite,
    allowFeedbackAccess,
    allowAdminAccess,
    allowUserManagementAccess,
    allowSpaceAdminAccess,
    allowAppRepositoryAccess,
    allowDebugRead,
  };
}
