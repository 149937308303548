import { ColDef, GridApi } from 'ag-grid-enterprise';

export const handleAfterGuiDetached = (
  api: GridApi,
  colDef: ColDef,
  values: string[],
  selectedValues: string[],
) => {
  const filterModel = api.getFilterModel();
  const filterField = colDef.field as string;
  // If no values are selected, don't destroy the filter
  if (!selectedValues.length) return;
  if (
    (!filterModel[filterField]?.value && !filterModel[filterField]?.filterType) ||
    values.length === selectedValues.length
  ) {
    api.destroyFilter(filterField);
  }
};
