import { AgGridReact } from 'ag-grid-react';

export const getLastOccupiedColumn = (agGridRef: React.RefObject<AgGridReact>) => {
  const lastOccupiedColumnIndexes: number[] = [];

  const columnDefs = agGridRef?.current?.api.getColumnDefs() || [];

  // Loop through each row to find the last non-empty column
  agGridRef?.current?.api.forEachNode((node) => {
    const rowData = node.data;
    let lastColumnIndex = -1;

    // Loop through each column in the row data
    columnDefs.forEach((colDef, index) => {
      const value = rowData[colDef.headerName as string];
      if (value !== null && value !== undefined && value !== '') {
        lastColumnIndex = index;
      }
    });

    lastOccupiedColumnIndexes.push(lastColumnIndex);
  });

  // Return the max last occupied index across all rows
  return Math.max(...lastOccupiedColumnIndexes);
};
