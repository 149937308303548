'use client';

import { useEffect } from 'react';

export const useWarnBeforeReload = (shouldWarn: boolean) => {
  useEffect(() => {
    if (!shouldWarn) return;
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldWarn]);
};
