import { IngestionState } from '../@generated/graphql';
import {
  DELETABLE_INGESTION_STATES,
  FAILED_INGESTION_STATES,
  INGESTING_STATES,
} from './getIngestionStateType';

export const INGESTION_STATUS_TO_MESSAGE = {
  [IngestionState.Queued]: 'Uploading file',
  [IngestionState.IngestionReading]: 'Reading file',
  [IngestionState.IngestionEmbedding]: 'Analyzing file content',
  [IngestionState.Finished]: 'Preparing to chat',
  [IngestionState.MalwareScanning]: 'Scanning for malware',
};

export const isIngestionDone = (contentIngestionState: IngestionState) => {
  return DELETABLE_INGESTION_STATES.includes(contentIngestionState);
};

export const isIngestingContent = (contentIngestionState: IngestionState) => {
  return INGESTING_STATES.includes(contentIngestionState);
};

export const isIngestionFailed = (contentIngestionState: IngestionState) => {
  return FAILED_INGESTION_STATES.includes(contentIngestionState);
};

export const INGESTION_STATUS_TO_PROGRESS = {
  [IngestionState.Queued]: 20,
  [IngestionState.MalwareScanning]: 40,
  [IngestionState.IngestionReading]: 60,
  [IngestionState.IngestionEmbedding]: 80,
  [IngestionState.Finished]: 100,
};

export const getIngestionProgressValue = (ingestionState: IngestionState): number => {
  let progress = 0;
  switch (ingestionState) {
    case IngestionState.Failed:
    case IngestionState.FailedMalwareFound:
    case IngestionState.FailedCreatingChunks:
    case IngestionState.FailedEmbedding:
    case IngestionState.FailedGettingFile:
    case IngestionState.FailedImage:
    case IngestionState.FailedParsing:
    case IngestionState.FailedRedelivered:
    case IngestionState.FailedTooLessContent:
      progress = 100;
      break;
    case IngestionState.MalwareScanning:
      progress = 20;
      break;
    case IngestionState.Queued:
      progress = 20;
      break;
    case IngestionState.IngestionReading:
      progress = 45;
      break;
    case IngestionState.IngestionChunking:
      progress = 50;
      break;
    case IngestionState.IngestionEmbedding:
      progress = 60;
      break;
    case IngestionState.Finished:
    default: {
      progress = 100;
    }
  }
  return progress;
};
