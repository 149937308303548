import { ColDef } from 'ag-grid-enterprise';

// ZZ = 702 columns
export const TOTAL_COLUMNS = 20; // last column name is CZ
export const MIN_ROW_HEIGHT = 40;

export type DefaultEmptyColumn = {
  headerName: string;
  field: string;
};

export const idColumn: ColDef = {
  field: 'id',
  headerName: '',
  pinned: 'left',
  rowDrag: false,
  maxWidth: 40,
  lockPosition: 'left',
  lockVisible: false,
  wrapText: false,
  autoHeight: false,
  cellClass: 'custom-lock-pinned',
  cellStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F7F7F5',
  },
};

export const defaultColDef: ColDef<unknown> = {
  sortable: false,
  minWidth: 100,
  editable: true,
  wrapText: true,
  autoHeight: false,
  cellClass: 'custom-ag-cell',
  context: false,
  suppressHeaderMenuButton: true,
  suppressHeaderContextMenu: true,
  enableCellChangeFlash: true,
  cellEditor: 'agLargeTextCellEditor',
  cellEditorPopup: false,
  cellEditorParams: {
    rows: 100,
    cols: 50,
    maxLength: 10000,
  },
  cellRenderer: 'customCellRenderer',
};
