import { DefaultEmptyColumn } from '../utils/constants';
import { generateExcelHeaders } from './generateExcelHeaders';

export const generateEmptyColumns = (numberOfColumns = 26): DefaultEmptyColumn[] => {
  const emptyColumns = generateExcelHeaders(numberOfColumns).map((header) => {
    return {
      headerName: header,
      field: header,
    };
  });
  return emptyColumns;
};
