'use client';
import type { TransformedMagicTableSheet } from '@unique/shared-library/@generated/graphql';
import { IconArrowUp, IconHistory } from '@unique/icons';
import {
  BodyScrollEndEvent,
  CellContextMenuEvent,
  CellEditingStartedEvent,
  CellEditingStoppedEvent,
  CellEditRequestEvent,
  ColDef,
  ColumnResizedEvent,
  GetContextMenuItems,
  GetRowIdParams,
  GridReadyEvent,
  MenuModule,
  ModuleRegistry,
  RowSelectedEvent,
  SideBarDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
} from 'ag-grid-enterprise';
import 'ag-grid-enterprise/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-enterprise/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { AgGridReact, AgGridReactProps, CustomCellRendererProps } from 'ag-grid-react';
import useDebouncedCallback from 'beautiful-react-hooks/useDebouncedCallback';
import lodash from 'lodash';
import {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { renderToString } from 'react-dom/server';
import './styles/ag-theme-unique.css'; // Custom Theme applied to the Data Grid
import { defaultColDef, idColumn, MIN_ROW_HEIGHT } from './utils/constants';
import { ButtonIcon, ButtonVariant } from '../..';
import {
  handleRowResize,
  calculateTextHeight,
  getTableData,
  MagicTableResult,
  exportAsExcel,
  cellEditRequestValues,
  getColumnIndex,
  getLastOccupiedRow,
  getRowHeight,
  onRowDataUpdated,
  getLastOccupiedColumn,
  getRowClass,
} from './helpers';
import { MagicMarkdownEditor } from '../MagicMarkdownEditor';

ModuleRegistry.registerModules([MenuModule]);

export type CellEditing = {
  value: string | number;
  columnId: string;
  rowId: number | null;
};

export type CustomMagicTableRow = { [x: string]: string };

interface MagicTableProps {
  onRowSelected?: (selected: unknown) => void;
  cellEditingStart?: (event: CellEditing) => void;
  cellEditingEnd?: (event: CellEditing) => void;
  className?: string;
  handleCellEditRequest: ({ rowIndex, columnId, newValue, event }: CellEditRequest) => void;
  contextMenuItems?: GetContextMenuItems;
  loading?: boolean;
  onGridReady?: (event: GridReadyEvent) => void;
  sidebar?: SideBarDef;
  onCellContextMenu?: (event: CellContextMenuEvent) => void;
  CustomCellRenderer?: (props: CustomCellRendererProps) => JSX.Element;
  handleColumnResize?: (event: ColumnResizedEvent) => void;
  pinnedTopRowData?: TransformedMagicTableSheet['rows'];
  isTableBusy?: boolean;
}
export interface MagicTableRefHandles {
  updateColumnDefs: (newColDefs: ColDef[]) => void;
  updateRowData: (newRowData: CustomMagicTableRow[]) => void;
  getTableData: () => MagicTableResult[];
  exportAsExcel: (fileName: string) => void;
  stopEditingCell: () => void;
  updateCellValues: (rowNode: number, columnNode: string, newValue: string) => void;
  getLastOccupiedColumn: () => number;
  getColumnIndex: (columnId: string) => number;
  getColumnAtIndex: (index: number) => string;
  setRowUpdate: (rowIndex: number, data: CustomMagicTableRow[]) => void;
  getLastOccupiedRow: () => number;
  setSideBarVisible: (value?: boolean) => void;
  setSideBar: (def: SideBarDef | string | string[] | boolean) => void;
  openToolPanel: (id: string) => void;
  scrollToRowAtIndex: (rowIndex: number) => void;
  scrollToLastRow: () => void;
  refreshCells: () => void;
}

export interface CellEditRequest {
  rowIndex: number;
  columnId: string;
  newValue: string;
  event: CellEditRequestEvent;
}

const Table = forwardRef<MagicTableRefHandles, MagicTableProps & AgGridReactProps<unknown>>(
  (props, ref) => {
    const {
      onRowSelected,
      cellEditingStart,
      cellEditingEnd,
      className = 'h-full',
      handleCellEditRequest,
      contextMenuItems,
      loading,
      onGridReady,
      sidebar,
      onCellContextMenu,
      CustomCellRenderer,
      handleColumnResize,
      pinnedTopRowData,
      isTableBusy,
    } = props;
    const [rowData, setRowData] = useState<unknown[]>([]);

    const agGridRef = useRef<AgGridReact>(null);

    const [colDefs, setColDefs] = useState<ColDef<unknown>[]>([]);

    const [showBackToTop, setShowBackToTop] = useState(false);

    const updateColumnDefs = (newColDefs: ColDef[]) => {
      setColDefs((prevColDefs) => {
        if (lodash.isEqual(prevColDefs, newColDefs)) {
          return prevColDefs;
        }
        return newColDefs;
      });
    };

    const updateRowData = (newRowData: CustomMagicTableRow[]) => {
      setRowData((prevRowData) => {
        if (lodash.isEqual(prevRowData, newRowData)) {
          return prevRowData;
        }
        return newRowData;
      });
    };

    const handleSelect = useCallback((event: RowSelectedEvent) => {
      onRowSelected?.(event.data);
    }, []);

    const setRowUpdate = (rowIndex: number, data: CustomMagicTableRow[]) => {
      const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowIndex);
      if (row) {
        row.setData(data);
      }
    };

    const autoSizeStrategy = useMemo<
      | SizeColumnsToFitGridStrategy
      | SizeColumnsToFitProvidedWidthStrategy
      | SizeColumnsToContentStrategy
    >(() => {
      return {
        type: 'fitGridWidth',
        defaultMinWidth: 200,
      };
    }, []);

    const getCellValue = (event: CellEditingStartedEvent | CellEditingStoppedEvent) => {
      return event.api.getCellValue({ colKey: event.column, rowNode: event.node });
    };

    const onCellEditingStarted = useCallback((event: CellEditingStartedEvent) => {
      const cellValue = getCellValue(event);
      cellEditingStart?.({
        value: cellValue,
        columnId: event.column.getId(),
        rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
      });
    }, []);

    const onCellEditingStopped = useCallback((event: CellEditingStoppedEvent) => {
      const cellValue = getCellValue(event);
      cellEditingEnd?.({
        value: cellValue,
        columnId: event.column.getId(),
        rowId: event.rowIndex !== null ? event.rowIndex + 1 : null,
      });
      // The new MagicTableCellEditor does not resize when the Editor is closed.
      // This is a way to make it resize the row height when it is closed.
      handleRowResize(agGridRef, hasTopPinnedRow);
    }, []);

    // Get the column name at the given index
    const getColumnAtIndex = (index: number) => {
      const columnDefs = agGridRef.current?.api?.getColumnDefs() || [];
      return columnDefs[index + 1]?.headerName || '';
    };

    const getRowId = useCallback(
      (params: GetRowIdParams) => String((params.data as { id: string }).id),
      [],
    );

    const onCellEditRequest = useCallback((event: CellEditRequestEvent) => {
      const cellValues = cellEditRequestValues(event);
      if (!cellValues) return;

      handleCellEditRequest(cellValues);
    }, []);

    const updateCellValues = useCallback(
      (rowNode: number, columnNode: string, newValue: string) => {
        const row = agGridRef.current?.api.getDisplayedRowAtIndex(rowNode);

        if (row) {
          const contentHeight = calculateTextHeight(newValue);
          if ((row?.rowHeight ?? MIN_ROW_HEIGHT) < contentHeight) {
            row.setRowHeight(contentHeight);
            agGridRef.current?.api.onRowHeightChanged();
          }
          row.setDataValue(columnNode, newValue);
        }
      },
      [],
    );

    const stopEditingCell = () => {
      agGridRef.current?.api.stopEditing(true);
    };

    // Sidebar
    const setSideBarVisible = (value?: boolean) => {
      if (!value) {
        agGridRef.current!.api.setSideBarVisible(agGridRef.current!.api.isSideBarVisible());
        return;
      }
      agGridRef.current!.api.setSideBarVisible(value);
    };

    const setSideBar = (def: SideBarDef | string | string[] | boolean) => {
      agGridRef.current!.api.setGridOption('sideBar', def);
    };

    const openToolPanel = (id: string) => {
      agGridRef.current!.api.openToolPanel(id);
    };

    const scrollToRowAtIndex = (rowIndex: number) => {
      agGridRef.current!.api.ensureIndexVisible(rowIndex);
    };

    const scrollToLastRow = () => {
      scrollToRowAtIndex(getLastOccupiedRow(agGridRef) + 1);
    };

    const scrollToTop = () => {
      scrollToRowAtIndex(0);
    };

    const hasTopPinnedRow = useMemo(
      () => typeof pinnedTopRowData !== 'undefined' && pinnedTopRowData?.length > 0,
      [pinnedTopRowData],
    );

    const onColumnResized = useDebouncedCallback(
      (event: ColumnResizedEvent) => {
        // Only update the row heights if a column was actually resized by the user
        // Otherwise this calculation runs whenever the window size changes which would impact performance
        if (event.source !== 'uiColumnResized') return;
        handleRowResize(agGridRef, hasTopPinnedRow);
        handleColumnResize?.(event);
      },
      [],
      150,
    );

    // Resize rows because they might not be loaded into DOM yet when they are not visible on the screen
    const handleBodyScrollEnd = useDebouncedCallback(
      (event: BodyScrollEndEvent) => {
        handleRowResize(agGridRef, hasTopPinnedRow);

        const { top, direction } = event;
        if (direction === 'vertical' && top >= MIN_ROW_HEIGHT) {
          setShowBackToTop(true);
        }
        if (direction === 'vertical' && top < MIN_ROW_HEIGHT) {
          setShowBackToTop(false);
        }
      },
      [],
      150,
    );

    const components = useMemo(
      () => ({
        customCellRenderer: CustomCellRenderer,
        magicMarkdownCellEditor: MagicMarkdownEditor,
      }),
      [],
    );

    const rowSelection = useMemo(
      () => ({
        mode: 'multiRow' as const,
        checkboxes: false,
        headerCheckbox: false,
        enableClickSelection: true,
      }),
      [],
    );

    const onColumnHeaderContextMenu = useCallback(() => null, []);

    const refreshCells = () => {
      agGridRef.current?.api.refreshCells({ force: true });
    };

    const getTableDataFn = useCallback(() => {
      return getTableData(agGridRef);
    }, [agGridRef]);

    const exportAsExcelFn = useCallback(
      (fileName: string) => {
        return exportAsExcel(agGridRef, fileName);
      },
      [agGridRef],
    );

    const getLastOccupiedColumnFn = useCallback(() => {
      return getLastOccupiedColumn(agGridRef);
    }, [agGridRef]);

    const getColumnIndexFn = useCallback(
      (columnId: string) => {
        return getColumnIndex(agGridRef, columnId);
      },
      [agGridRef],
    );

    const getLastOccupiedRowFn = useCallback(() => {
      return getLastOccupiedRow(agGridRef);
    }, [agGridRef]);

    useImperativeHandle(
      ref,
      () => ({
        updateColumnDefs,
        updateRowData,
        getTableData: getTableDataFn,
        exportAsExcel: exportAsExcelFn,
        stopEditingCell,
        updateCellValues,
        getLastOccupiedColumn: getLastOccupiedColumnFn,
        getColumnIndex: getColumnIndexFn,
        getColumnAtIndex,
        setRowUpdate,
        getLastOccupiedRow: getLastOccupiedRowFn,
        setSideBarVisible,
        setSideBar,
        openToolPanel,
        scrollToRowAtIndex,
        refreshCells,
        scrollToLastRow,
      }),
      [
        updateColumnDefs,
        updateRowData,
        stopEditingCell,
        updateCellValues,
        getColumnAtIndex,
        setRowUpdate,
        setSideBarVisible,
        setSideBar,
        openToolPanel,
        scrollToRowAtIndex,
        refreshCells,
        scrollToLastRow,
        getLastOccupiedColumnFn,
        getColumnIndexFn,
        getLastOccupiedRowFn,
        exportAsExcelFn,
      ],
    );

    const memoizedColDefs = useMemo(() => {
      const columns = [idColumn, ...colDefs];

      if (!isTableBusy) {
        return columns;
      }
      return columns.map((col) => ({ ...col, editable: false }));
    }, [colDefs, isTableBusy]);

    return (
      <div className={`ag-theme-quartz ag-theme-unique ${className}`}>
        <AgGridReact
          ref={agGridRef}
          debug={false}
          loading={loading}
          rowData={rowData}
          components={components}
          columnDefs={memoizedColDefs}
          icons={{
            'cell-history': renderToString(<IconHistory />),
          }}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          getRowHeight={getRowHeight}
          autoSizeStrategy={autoSizeStrategy}
          rowSelection={rowSelection}
          onColumnHeaderContextMenu={onColumnHeaderContextMenu}
          onRowSelected={handleSelect}
          readOnlyEdit
          onCellEditingStarted={onCellEditingStarted}
          onCellEditingStopped={onCellEditingStopped}
          getContextMenuItems={contextMenuItems}
          onRowDataUpdated={() => onRowDataUpdated(agGridRef, hasTopPinnedRow, rowData)}
          onCellEditRequest={onCellEditRequest}
          onGridReady={onGridReady}
          sideBar={sidebar}
          onCellContextMenu={onCellContextMenu}
          getRowClass={(params) => getRowClass(params)}
          onColumnResized={onColumnResized}
          onBodyScrollEnd={handleBodyScrollEnd}
          pinnedTopRowData={pinnedTopRowData}
        />
        {showBackToTop && (
          <div className="absolute bottom-5 flex w-full items-center justify-center">
            <ButtonIcon
              variant={ButtonVariant.TERTIARY}
              icon={<IconArrowUp />}
              onClick={scrollToTop}
              className="bg-background text-nowrap"
            >
              Back to Top
            </ButtonIcon>
          </div>
        )}
      </div>
    );
  },
);

export const MagicTable = memo(Table);
