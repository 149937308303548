import { handleRowResize } from './handleRowResize';
import { AgGridReact } from 'ag-grid-react';

// Resize the row height when the data is fully loaded
export const onRowDataUpdated = (
  agGridRef: React.RefObject<AgGridReact>,
  hasTopPinnedRow: boolean,
  rowData: unknown[],
) => {
  const hasActualData = rowData.some((data) => {
    return Object.keys(data as object).length > 2;
  });
  if (!hasActualData) return;
  handleRowResize(agGridRef, hasTopPinnedRow);
};
