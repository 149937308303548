import { ParagraphNode, TextNode } from 'lexical';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { CodeNode } from '@lexical/code';
import { TableNode, TableRowNode, TableCellNode } from '@lexical/table';
import { ListNode, ListItemNode } from '@lexical/list';
import { CodeHighlightNode } from '@lexical/code';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { TRANSFORMERS } from '@lexical/markdown';
import { LinkNode } from '@lexical/link';
import { logger } from '@unique/next-commons/logger';

import EditorTheme from './styles/EditorTheme';
import './styles/styles.css';
import { MarkdownEditPlugin } from './plugins';

const log = logger.child({
  package: 'MagicMarkdownEditor',
  namespace: 'index',
});

const editorConfig = {
  namespace: 'MagicMarkdownEditor',
  nodes: [
    HeadingNode,
    QuoteNode,
    ListNode,
    ListItemNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableRowNode,
    TableCellNode,
    ParagraphNode,
    TextNode,
    LinkNode,
  ],
  onError(error: Error) {
    log.error(error?.message);
    throw error;
  },
  theme: EditorTheme,
};

type Props = {
  value: string;
  placeholder?: string;
  onValueChange?: (value: string) => void;
};

function MagicMarkdownEditor({ value, placeholder = '', onValueChange }: Props) {
  const onChange = (markdown: string) => {
    onValueChange?.(markdown);
  };

  return (
    <div className="h-full overflow-y-auto">
      <LexicalComposer initialConfig={{ ...editorConfig }}>
        <RichTextPlugin
          contentEditable={
            <ContentEditable
              className="editor-input"
              aria-placeholder={placeholder}
              placeholder={<div className="editor-placeholder">{placeholder}</div>}
            />
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <AutoFocusPlugin />
        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
        <MarkdownEditPlugin onChange={onChange} initialText={value} />
      </LexicalComposer>
    </div>
  );
}

export { MagicMarkdownEditor };
