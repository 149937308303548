import { CellEditRequestEvent } from 'ag-grid-enterprise';

export const cellEditRequestValues = (
  event: CellEditRequestEvent,
): { rowIndex: number; columnId: string; newValue: string; event: CellEditRequestEvent } | null => {
  const oldData = event.data;
  const field = event.colDef.field;
  const newValue = event.newValue;
  const newData = { ...oldData };
  newData[field!] = event.newValue;

  if (newData[field!] === oldData[field!] || !newValue) return null;

  const rowIndex = event.node.sourceRowIndex;
  const columnId = event.colDef.field!;

  const tx = {
    update: [newData],
  };

  event.api.applyTransaction(tx);

  return { rowIndex, columnId, newValue, event };
};
