const escapeDollarSigns = (text: string) => {
  if (!text) return '';

  // Split text into code blocks and non-code blocks
  // Even indices are normal text, odd indices are code blocks
  return text
    .split(/(```[\s\S]*?```|`[^`]*`)/g)
    .map((part, index) => (index % 2 === 0 ? part.replace(/\$/g, '\\$') : part))
    .join('');
};

/** Function to detect LaTeX formulas and wrap them in dollar signs for rendering with remark-math */
export const wrapLatexInDollars = (text: string) => {
  if (!text) return '';

  // Regex for classic LaTeX inline math: \( ... \)
  const inlineMathRegex = /\\\((.+?)\\\)/g;
  // Regex for classic LaTeX block math: \[ ... \] with support for multiline content
  const blockMathRegex = /\\\[([\s\S]+?)\\\]/g;

  // Replace inline math
  text = text.replace(inlineMathRegex, (_, math) => `$${math}$`);

  // Replace block math
  text = text.replace(blockMathRegex, (_, math) => `$$${math}$$`);

  return text;
};

export const applyLatexFormat = (content: string) => wrapLatexInDollars(escapeDollarSigns(content));
