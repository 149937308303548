'use client';
import { ColumnItemProps, ColumnItems } from '@unique/component-library';
import { getColumnItemType, QUERY_VARIABLES_PAGINATED_CONTENT } from '@unique/shared-library';
import { FC, useCallback, useEffect, useState } from 'react';
import { File } from '../hooks/types';
import { PaginatedContentQueryVariables } from '../@generated/graphql';
import { usePaginatedContentQuery } from '../hooks/swr/usePaginatedContentQuery';

type FolderPathSelectionColumnProps = {
  folders: ColumnItemProps[];
  selectedFolderId: string;
  handleSetFileCount: (fileCount: number) => void;
};

const getUpdatedPaginatedContentQueryVariables = (ownerId: string, skip: number = 0) => {
  return {
    ...QUERY_VARIABLES_PAGINATED_CONTENT,
    where: {
      ...QUERY_VARIABLES_PAGINATED_CONTENT.where,
      ownerId: { equals: ownerId },
    },
    skip,
  };
};

export const FolderPathSelectionColumn: FC<FolderPathSelectionColumnProps> = ({
  folders,
  selectedFolderId,
  handleSetFileCount,
}) => {
  const [paginatedContentQueryVariables, setPaginatedContentQueryVariables] =
    useState<PaginatedContentQueryVariables>({});
  const [files, setFiles] = useState<File[]>([]);
  const [totalFileCount, setTotalFileCount] = useState<number>(1);

  const { data: content, isLoading: isLoadingFiles } = usePaginatedContentQuery(
    paginatedContentQueryVariables,
  );

  useEffect(() => {
    if (!selectedFolderId) return;
    if (selectedFolderId !== paginatedContentQueryVariables?.where?.ownerId?.equals) {
      setFiles([]);
    }
    setPaginatedContentQueryVariables(getUpdatedPaginatedContentQueryVariables(selectedFolderId));
  }, [selectedFolderId]);

  useEffect(() => {
    if (!content?.paginatedContent || !Object.values(paginatedContentQueryVariables).length) return;
    const newContent = content.paginatedContent?.nodes;

    if (newContent) {
      handleSetFileCount(content.paginatedContent?.totalCount);
      setFiles([...files, ...newContent]);
    }
    setTotalFileCount(content.paginatedContent?.totalCount);
  }, [content]);

  const mapFileToColumnItemProps = (file: File): ColumnItemProps => {
    return {
      label: file.title || file.key,
      type: getColumnItemType(),
      mimeType: file.mimeType,
      columnItemId: file.id,
      ownerId: file.ownerId,
    };
  };

  const loadNextPage = useCallback(() => {
    setPaginatedContentQueryVariables(
      getUpdatedPaginatedContentQueryVariables(selectedFolderId, files.length),
    );
  }, [files.length, selectedFolderId]);

  const paginationCounter = files.length > 0 ? totalFileCount - files.length : undefined;
  return (
    <ColumnItems
      items={[...folders, ...files.map(mapFileToColumnItemProps)]}
      isLoading={isLoadingFiles && !files.length}
      showPagination={!!totalFileCount && totalFileCount !== files.length}
      loadNextPage={() => loadNextPage()}
      paginationCounter={paginationCounter}
    />
  );
};
