import { AgGridReact } from 'ag-grid-react';

export interface MagicTableResult {
  position: number;
  rowData?: {
    columnId: string;
    value: unknown;
  }[];
}

/**
 * Get the table data in the JSON format
 * @param agGridRef - The ref object for the ag-Grid React component
 * @returns The table data in the JSON format
 */
export const getTableData = (agGridRef: React.RefObject<AgGridReact>): MagicTableResult[] => {
  const rowCount = agGridRef.current?.api.getDisplayedRowCount() || 0;

  return Array.from({ length: rowCount }, (_, i) => {
    const row = agGridRef.current?.api.getDisplayedRowAtIndex(i)?.data || {};
    const { id, ...rest } = row;

    if (Object.keys(rest).length > 0 && id !== undefined) {
      const rowData = Object.entries(rest).map(([columnId, value]) => ({ columnId, value }));
      return { position: id, rowData };
    }
    return null;
  }).filter(Boolean) as MagicTableResult[];
};
