import { FC } from 'react';
import { IconProps } from '.';

export const IconKeyFilled: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={height}
      width={width}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fill="currentColor"
        d="M14.5169 6.52161H8.63252C7.92847 4.52804 5.84598 3.18665 3.53374 3.64613C1.83662 3.98704 0.458172 5.35067 0.102443 7.04038C-0.50526 9.91585 1.67358 12.4504 4.4453 12.4504C6.37957 12.4504 8.02482 11.2128 8.63252 9.48601H8.8919L9.84792 10.442C10.137 10.7311 10.6038 10.7311 10.8929 10.442L11.8563 9.48601L12.8123 10.442C13.1014 10.7311 13.5757 10.7311 13.8647 10.442L15.7841 8.50776C16.0732 8.21873 16.0732 7.74442 15.7767 7.45539L15.043 6.73653C14.8948 6.59572 14.7095 6.52161 14.5169 6.52161ZM4.4453 10.2271C3.22248 10.2271 2.22199 9.22663 2.22199 8.00381C2.22199 6.78099 3.22248 5.7805 4.4453 5.7805C5.66811 5.7805 6.6686 6.78099 6.6686 8.00381C6.6686 9.22663 5.66811 10.2271 4.4453 10.2271Z"
      />
    </svg>
  );
};
