import { MIN_ROW_HEIGHT } from '../utils/constants';
import { MagicTableRow } from '@unique/shared-library/@generated/graphql';
import { calculateTextHeight } from './calculateTextHeight';

const minCharsPerLine = 25;

export const calculateRowHeight = (row: MagicTableRow) => {
  const longestContent = Object.values(row).reduce((acc, val) => {
    return val.length > acc.length ? val : acc;
  }) as string;
  if (longestContent.length < minCharsPerLine) return MIN_ROW_HEIGHT;
  return calculateTextHeight(longestContent);
};
