import { DefaultEmptyColumn } from '../utils/constants';
import { calculateRowHeight } from './calculateRowHeight';
import { MagicTableRow } from '@unique/shared-library/@generated/graphql';
import { MIN_ROW_HEIGHT } from '../utils/constants';

export const transformMagicTableData = (
  tableData: MagicTableRow[],
  columnDefs: DefaultEmptyColumn[],
) => {
  // Create a lookup map for fast access to tableData by row ID
  // and Calculate the height of each row based on the longest content in the row
  const tableDataMap = new Map<number, MagicTableRow>(
    tableData?.map((row) => {
      const computedHeight = calculateRowHeight(row);
      const height = computedHeight < MIN_ROW_HEIGHT ? MIN_ROW_HEIGHT : computedHeight;
      return [Number(row.id), { ...row, rowHeight: height }];
    }),
  );

  // Define function to create a row with either actual data or empty fields
  const createRowData = (id: number, data: { [key: string]: string } = {}) => {
    return columnDefs.reduce(
      (acc: { [key: string]: string }, colDef) => {
        acc[colDef.field] = data[colDef.field] || '';
        acc['rowHeight'] = data.rowHeight?.toString() || MIN_ROW_HEIGHT.toString();
        return acc;
      },
      { id: id.toString() },
    );
  };

  // Calculate the maximum row ID
  const maxRowId = Math.max(...tableData.map((row) => Number(row.id)), 0);

  // Generate all rows, filling in gaps with empty rows
  const allRowData = Array.from({ length: maxRowId + 101 }, (_, index) => {
    const rowId = index + 1;
    const rowData = tableDataMap.get(rowId) || {}; // Get data or default to empty object
    return createRowData(rowId, rowData);
  });
  return allRowData;
};
