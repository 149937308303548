'use client';
import cn from 'classnames';
import { FC, ReactNode, useState, memo, useCallback, useEffect } from 'react';

interface Tab {
  title: string;
  icon?: ReactNode;
  component?: ReactNode;
}

interface TabProps {
  tabs: Tab[];
  defaultTab?: number;
  selectedTab?: number;
  component?: ReactNode;
  headerClassName?: string;
  onTabChange?: (tabId: number) => void;
}

interface TabItemProps {
  tab: Tab;
  id: string;
  isLast: boolean;
  isActive: boolean;
  onClick: () => void;
}

const TabItem: FC<TabItemProps> = memo(({ tab, isActive, isLast, onClick, id }) => {
  return (
    <li aria-current={isActive ? 'step' : undefined}>
      <button
        id={id}
        role="tab"
        onClick={onClick}
        aria-selected={isActive}
        className={cn('inline-flex items-center justify-center gap-1 rounded-t-lg px-4 py-3', {
          'mr-4 md:mr-0': isLast,
          'border-b-surface bg-surface cursor-auto': isActive,
          'bg-background-variant text-on-background-dimmed hover:text-on-background-main':
            !isActive,
        })}
      >
        {tab.icon && <span>{tab.icon}</span>}
        <span className="subtitle-1 truncate">{tab.title}</span>
      </button>
    </li>
  );
});

export const Tabs: FC<TabProps> = ({
  tabs,
  component,
  onTabChange,
  selectedTab,
  defaultTab = 1,
}) => {
  if (!tabs?.length) {
    throw new Error('Tabs component requires at least one tab');
  }

  const [currentTab, setCurrentTab] = useState(defaultTab);

  const handleTabClick = useCallback(
    (tabId: number) => {
      setCurrentTab(tabId);
      onTabChange?.(tabId);
    },
    [onTabChange],
  );

  useEffect(() => {
    setCurrentTab(selectedTab ?? defaultTab);
  }, [selectedTab]);

  return (
    <>
      <div className="bg-background">
        <div className="mx-auto flex max-w-[928px] flex-wrap gap-x-5 gap-y-5 pl-4 pr-0 pt-4 md:items-center md:px-8 md:pt-8">
          <nav
            role="tablist"
            aria-label="Tabs navigation"
            className="w-full overflow-x-auto [-ms-overflow-style:none] [scrollbar-width:none] [&::-webkit-scrollbar]:hidden"
          >
            <ul className="flex gap-6 text-center">
              {tabs.map((tab, tabIdx) => {
                const tabId = tabIdx + 1;
                const panelId = `tab-${tabId}`;

                return (
                  <TabItem
                    tab={tab}
                    key={tabId}
                    id={panelId}
                    isLast={tabId === tabs.length}
                    isActive={tabId === currentTab}
                    onClick={() => handleTabClick(tabId)}
                  />
                );
              })}
            </ul>
          </nav>
        </div>
      </div>
      <div role="tabpanel" aria-labelledby={`tab-${currentTab}`}>
        {component ?? tabs[currentTab - 1].component}
      </div>
    </>
  );
};
