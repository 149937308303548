/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  GraphqlFetcherArgs,
  Service,
  useClientSWR,
  useClientSWRMutation,
} from '@unique/next-commons/swr';
import { SWRMutationConfiguration } from 'swr/mutation';
import {
  AgreedToTermsAndConditionsDocument,
  AgreedToTermsAndConditionsMutation,
  AgreedToTermsAndConditionsMutationVariables,
  CreateTermsAndConditionsDocument,
  CreateTermsAndConditionsMutation,
  CreateTermsAndConditionsMutationVariables,
  LatestTermsAndConditionsDocument,
  LatestTermsAndConditionsQuery,
  LatestTermsAndConditionsQueryVariables,
} from '../../@generated/graphql';

export function useCreateTermsAndConditionsMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      CreateTermsAndConditionsMutation,
      any,
      GraphqlFetcherArgs<any>,
      CreateTermsAndConditionsMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    CreateTermsAndConditionsMutation,
    CreateTermsAndConditionsMutationVariables
  >(key, CreateTermsAndConditionsDocument, options, Service.NODE_CHAT);
}

export function useLatestTermsAndConditionsQuery(
  variables?: LatestTermsAndConditionsQueryVariables,
) {
  return useClientSWR<LatestTermsAndConditionsQuery, LatestTermsAndConditionsQueryVariables>(
    getUseLatestTermsAndConditionsQueryKey(variables),
    {
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
    Service.NODE_CHAT,
  );
}

export function useAgreedToTermsAndConditionsMutation(
  key: GraphqlFetcherArgs<any>,
  options?: Partial<
    SWRMutationConfiguration<
      AgreedToTermsAndConditionsMutation,
      any,
      GraphqlFetcherArgs<any>,
      AgreedToTermsAndConditionsMutationVariables
    >
  >,
) {
  return useClientSWRMutation<
    AgreedToTermsAndConditionsMutation,
    AgreedToTermsAndConditionsMutationVariables
  >(key, AgreedToTermsAndConditionsDocument, options, Service.NODE_CHAT);
}

export function getUseLatestTermsAndConditionsQueryKey(
  variables?: LatestTermsAndConditionsQueryVariables,
): GraphqlFetcherArgs<LatestTermsAndConditionsQueryVariables> {
  return { query: LatestTermsAndConditionsDocument, variables };
}
