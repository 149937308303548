'use client';

import { FC, ReactNode, useContext, useEffect, useState, createContext } from 'react';
import { ToastVariant, useToast } from '@unique/shared-library';
import { LoadingScreen } from '@unique/component-library';
import { ClientContext, ErrorHandlerContext, Service } from '@unique/next-commons/swr';
import { getSdk, MeQuery } from '../@generated/graphql';
import { logger } from '@unique/next-commons/logger';
import { serializeError } from 'serialize-error';
import { useRoles } from '@unique/next-commons/authorization';
import { Assignment } from '../hooks/types';

export type UserProfileContextProps = {
  isLoading: boolean;
  assignments: Assignment[];
};

export const UserProfileContext = createContext<UserProfileContextProps>({
  isLoading: false,
  assignments: [],
});

type UserProfileProviderProps = {
  children: ReactNode;
  required?: boolean;
};

const log = logger.child({
  package: 'chat',
  namespace: 'providers:UserProfileProvider',
});

/**
 *
 * @param required boolean  - if true, the provider will show a loading screen until the user profile is loaded
 * @returns
 */
export const UserProfileProvider: FC<UserProfileProviderProps> = ({ children, required }) => {
  const { showToast } = useToast();
  const { allowSpaceAdminAccess } = useRoles();
  const { setError } = useContext(ErrorHandlerContext);
  const [isLoading, setIsLoading] = useState(true);

  const clientContext = useContext(ClientContext);
  const defaultClient = clientContext?.defaultClient;
  const clients = clientContext?.clients;

  const [assignments, setAssignments] = useState<Assignment[]>([]);

  // skip fetching user profile if user is space admin
  // we only need to fetch user profile if user is not space admin to get the assignments for the user
  // this is stored in a const because more role checks might be added in the future
  const shouldSkipAssignmentCheck = allowSpaceAdminAccess;

  useEffect(() => {
    if (shouldSkipAssignmentCheck) {
      setIsLoading(false);
      return;
    }
    if (defaultClient && clients) {
      const sdk = getSdk(clients[Service.NODE_SCOPE_MANAGEMENT]);
      sdk
        .Me()
        .then((response: MeQuery) => {
          setAssignments(response.me.assignments);
        })
        .catch((error: Error) => {
          if (required) {
            setError(error);
          } else {
            log.error({ error: serializeError(error) }, 'Failed to fetch user profile');
            showToast &&
              showToast({
                message: 'Failed to fetch user profile',
                variant: ToastVariant.ERROR,
                duration: 6000,
              });
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [defaultClient, clients]);

  return (
    <UserProfileContext.Provider value={{ isLoading, assignments }}>
      {required && isLoading && <LoadingScreen />}
      {required && !isLoading && children}
      {!required && children}
    </UserProfileContext.Provider>
  );
};
