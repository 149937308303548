import { AgGridReact } from 'ag-grid-react';
import { MIN_ROW_HEIGHT } from '../utils/constants';
import { getRowHeightByDomElement } from './getRowHeightByDomElement';

export const handleRowResize = (
  agGridRef: React.RefObject<AgGridReact>,
  hasTopPinnedRow?: boolean,
) => {
  if (!agGridRef.current) return;
  // Get all columns
  const allColumns = agGridRef.current.api.getColumns();
  if (!allColumns?.length) return;

  // Create an array to store the maximum height for each row
  const rowHeights: number[] = [];

  // Iterate over each row
  agGridRef.current.api.forEachNode((rowNode) => {
    const rowHeight = getRowHeightByDomElement(rowNode, allColumns);
    // Store the maximum height for the row
    if (rowNode?.rowIndex && rowHeight) {
      rowHeights[rowNode.rowIndex] = rowHeight;
    }
  });
  // Set the row heights
  agGridRef.current.api.forEachNode((rowNode) => {
    const shouldSetRowHeight =
      rowNode?.rowIndex &&
      rowHeights[rowNode.rowIndex] > MIN_ROW_HEIGHT &&
      rowHeights[rowNode.rowIndex] !== rowNode.rowHeight;
    const isFirstRow = rowNode.rowIndex === 0;

    if (!shouldSetRowHeight && !isFirstRow) return;

    // we want to hide the first row as it is already pinned (height to 0)
    if (rowNode?.rowIndex === 0 && !rowNode.rowPinned && hasTopPinnedRow) {
      rowNode.setRowHeight(0);
    } else if (rowNode?.rowIndex && shouldSetRowHeight) {
      rowNode.setRowHeight(rowHeights[rowNode.rowIndex]);
    }
  });
  // Notify the grid to update the row heights
  agGridRef.current.api.onRowHeightChanged();
};
