import { IconInfo } from '@unique/icons';
import cn from 'classnames';
import { ReactNode } from 'react';
import { TooltipSize } from '../enums/tooltip';

// TODO: Refactor component https://unique-ch.atlassian.net/browse/UN-10225
// e.g. fixed positions, swap wrapper and children,...
type Props = {
  children: ReactNode;
  wrapper?: ReactNode;
  position?: 'bottom';
  arrowPosition?: 'bottom' | 'left' | 'none' | 'right';
  className?: string;
  wrapperClasses?: string;
  disabled?: boolean;
  size?: TooltipSize;
};

const defaultTooltipClasses =
  'text-left tracking-4 font-normal items-top inset group-focus/tooltip:!opacity-100 group-hover/tooltip:!opacity-100 font-bold leading-5 max-w-[260px] -right-[15px] mt-1 absolute z-30 text-on-secondary body-2 leading-5 bg-secondary-variant opacity-0 group-hover/tooltip:!pointer-events-auto pointer-events-none normal-case transition-opacity shadow-strong whitespace-normal';

const arrowClasses = {
  none: '',
  bottom:
    'top-[20px] after:border-b-secondary-variant after:border-t-transparent after:border-r-transparent after:border-l-transparent after:border-solid after:absolute after:top-[-14px] after:right-[16px] after:border-[7px] after:ml-[-7px]',
  left: 'after:left-[-7px] after:border-r-secondary-variant after:border-t-transparent after:border-b-transparent after:border-l-transparent after:border-solid after:absolute after:top-1/2 after:-mt-1.5 after:border-[7px] after:ml-[-7px]',
  right:
    'after:right-[-7px] after:border-l-secondary-variant after:border-t-transparent after:border-b-transparent after:border-r-transparent after:border-solid after:absolute after:top-1/2 after:-mt-1.5 after:border-[7px] after:mr-[-7px]',
};

const positionClasses = {
  bottom: 'left-1/2 right-0 top-7 min-w-max -translate-x-1/2',
};

const tooltipSizeClasses = {
  [TooltipSize.SMALL]: 'px-[8px] py-[4px] rounded-[8px] ',
  [TooltipSize.NORMAL]: 'px-4 py-3 rounded-[14px] min-w-[140px]',
};

export const Tooltip = (props: Props) => {
  const {
    disabled,
    children,
    position,
    wrapper,
    arrowPosition = 'left',
    className,
    wrapperClasses,
    size = TooltipSize.NORMAL,
  } = props;

  return (
    <div
      className={cn(wrapperClasses, {
        'group/tooltip relative after:absolute': true,
        '!cursor-not-allowed': disabled,
      })}
    >
      {wrapper && <>{wrapper}</>}
      {!wrapper && <IconInfo width="16px" height="16px" />}
      <div
        className={cn(
          defaultTooltipClasses,
          arrowClasses[arrowPosition],
          position ? positionClasses[position] : '',
          className,
          tooltipSizeClasses[size],
        )}
      >
        {children}
      </div>
    </div>
  );
};
