import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InputField } from '../../InputField';
import IndeterminateCheckbox from '../../IndeterminateCheckbox';
import { FilterCheckbox } from './FilterCheckbox';
import { useGridFilter } from 'ag-grid-react';
import { IAfterGuiAttachedParams, IDoesFilterPassParams } from 'ag-grid-enterprise';

type Props = {
  onSelectAll: (newSelected: boolean) => void;
  filterList: string[];
  checkedFilters: string[];
  onChange: (reference: string) => void;
  onFilterPass: (node: IDoesFilterPassParams) => boolean;
  onAfterGuiDetached: () => void;
  searchTerm?: string;
  setSearchTerm?: (value: string) => void;
  showSearch?: boolean;
};

export const CustomMagicTableFilter = ({
  searchTerm,
  setSearchTerm,
  onSelectAll,
  filterList,
  checkedFilters,
  onChange,
  showSearch = true,
  onFilterPass,
  onAfterGuiDetached,
}: Props) => {
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [closeFilter, setCloseFilter] = useState<(() => void) | undefined>();
  const [isRendered, setIsRendered] = useState(false);

  const customFilterRef = useRef<HTMLDivElement>(null);

  const handleSelectAll = useCallback(() => {
    const newSelected = !isAllSelected;
    setIsAllSelected(newSelected);
    onSelectAll(newSelected);
  }, [isAllSelected, onSelectAll]);

  const filteredFilterList = useMemo(() => {
    return filterList.filter((filterItem) =>
      filterItem.toLowerCase().includes(searchTerm?.toLowerCase() ?? ''),
    );
  }, [filterList, searchTerm]);

  useEffect(() => {
    setIsAllSelected(checkedFilters.length === filterList.length);
  }, [checkedFilters, filterList]);

  const doesFilterPass = useCallback(
    (node: IDoesFilterPassParams) => {
      return onFilterPass(node);
    },
    [onFilterPass],
  );

  const afterGuiAttached = useCallback(
    ({ hidePopup }: IAfterGuiAttachedParams) => {
      setCloseFilter(() => hidePopup);
      setIsRendered(true);
    },
    [setCloseFilter],
  );

  const afterGuiDetached = useCallback(() => {
    onAfterGuiDetached();
    setIsRendered(false);
  }, [onAfterGuiDetached]);

  useGridFilter({
    doesFilterPass,
    afterGuiDetached,
    afterGuiAttached,
  });

  useEffect(() => {
    const handleCloseFilter = (e: KeyboardEvent) => {
      if (!customFilterRef.current && !isRendered) return;
      if (e.key === 'Escape' || e.key === 'Enter') {
        if (!closeFilter) return;
        closeFilter();
      }
    };
    // Because the filter is rendered in a portal, we need to listen to the keydown event on the document
    document.addEventListener('keydown', handleCloseFilter);
    return () => {
      document.removeEventListener('keydown', handleCloseFilter);
    };
  }, [closeFilter, isRendered]);

  return (
    <div
      className="align-items-center flex w-full max-w-md flex-col gap-2 rounded-md p-3"
      ref={customFilterRef}
    >
      {showSearch && (
        <div className="flex w-full flex-col">
          <InputField
            name="customCheckBoxFilter"
            value={searchTerm}
            className="border-control focus:border-primary-cta text-on-surface bg-surface w-full rounded-md font-normal"
            autoFocus
            placeholder="Search..."
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm?.(e.target.value)}
          />
        </div>
      )}
      <div className="flex max-w-md flex-col">
        {!searchTerm && (
          <div
            className="text-on-surface bg-surface flex items-center gap-2 rounded-md p-1"
            onClick={handleSelectAll}
          >
            <IndeterminateCheckbox checked={isAllSelected} onChange={handleSelectAll} />
            Select All
          </div>
        )}
        {filteredFilterList.map((filterItem) => (
          <FilterCheckbox
            filterItem={filterItem}
            key={filterItem}
            isChecked={checkedFilters.includes(filterItem)}
            onChange={onChange}
          />
        ))}
      </div>
    </div>
  );
};
