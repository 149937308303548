import { IconArrowDown } from '@unique/icons';
import { FC, ReactNode } from 'react';
import cn from 'classnames';
import Badge from './Badge';

export interface Breadcrumb {
  label: string | ReactNode;
  id: string;
  badgeText?: string;
  isActive?: boolean;
  onClick?: () => void;
}

type BreadcrumbsProps = {
  items: Breadcrumb[];
  lastBreadcrumbClasses?: string;
};

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ items, lastBreadcrumbClasses = '' }) => {
  return (
    <div className="subtitle-1 flex items-center gap-x-2 truncate whitespace-nowrap text-sm font-semibold">
      {items.map((item, index) => {
        return (
          <div
            key={item.id}
            className={cn({
              'shrink-1 flex items-center gap-x-2 overflow-hidden whitespace-nowrap': true,
              'text-on-background-main': item.isActive,
              'text-on-background-dimmed': !item.isActive,
              'cursor-pointer': !!item.onClick,
            })}
            onClick={item.onClick ? item.onClick : undefined}
          >
            {index > 0 && (
              <IconArrowDown
                width="12px"
                height="12px"
                className="text-on-background-dimmed shrink-0 -rotate-90 transform"
              />
            )}
            <div
              className={cn({
                'shrink-1 min-w-[20px] max-w-[150px] gap-x-2 overflow-auto truncate whitespace-nowrap leading-[18px]':
                  true,
                [lastBreadcrumbClasses]: item.isActive,
              })}
              title={typeof item.label === 'string' ? item.label : ''}
            >
              {item.label}
            </div>
            {item.badgeText && <Badge variant="info">{item.badgeText}</Badge>}
          </div>
        );
      })}
    </div>
  );
};
