import { useCallback, useEffect, useMemo, useState } from 'react';

import type { IDoesFilterPassParams } from 'ag-grid-enterprise';
import type { CustomFilterProps } from 'ag-grid-react';
import { CustomMagicTableFilter } from './CustomMagicTableFilter';
import { handleAfterGuiDetached } from '../helpers';

export const ValueMatchFilter = ({
  model,
  onModelChange,
  getValue,
  api,
  colDef,
}: CustomFilterProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams) => {
      // Ignore the pinned row
      if (node.id === '1') return true;

      const value = getValue(node).toString();

      const result = model!.value.split('~').includes(value);

      return result;
    },
    [model],
  );

  const values = useMemo(() => {
    const rowData = new Set<string>();
    api.forEachNode((node) => {
      if (!node?.data) return;
      const textValue = getValue(node).toString();
      if (!textValue) return;
      rowData.add(textValue);
    });
    return Array.from(rowData);
  }, [api]);

  const afterGuiDetached = useCallback(() => {
    handleAfterGuiDetached(api, colDef, values, checkedValues);
  }, [api, checkedValues, values, colDef]);

  useEffect(() => {
    setCheckedValues(values);
  }, [values]);

  const handleChange = useCallback(
    (value: string) => {
      setCheckedValues((prevValues) => {
        const newValues = prevValues.includes(value)
          ? prevValues.filter((ref) => ref !== value)
          : [...prevValues, value];

        onModelChange({
          value: newValues.join('~'),
        });

        return newValues;
      });
    },
    [onModelChange, setCheckedValues],
  );

  const handleSelectAll = (newSelected: boolean) => {
    const newValues = newSelected ? values : [];
    setCheckedValues(newValues);
    // The filter originally uses a string to store the values. Since we have multiple values, we need to join them with a `~`
    // So that we can split them later when filtering for easy comparison between the filtered texts and the text in the cells
    // We do a Split in line 26 for the comparison.
    onModelChange({
      value: newSelected ? values.join('~') : '',
    });
  };

  return (
    <CustomMagicTableFilter
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onSelectAll={handleSelectAll}
      filterList={values}
      checkedFilters={checkedValues}
      onChange={handleChange}
      onFilterPass={doesFilterPass}
      onAfterGuiDetached={afterGuiDetached}
    />
  );
};
