import {
  $convertToMarkdownString,
  TRANSFORMERS,
  $convertFromMarkdownString,
} from '@lexical/markdown';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useRef } from 'react';

type Props = {
  initialText: string;
  onChange: (markdown: string) => void;
};

export const MarkdownEditPlugin = ({ onChange, initialText }: Props) => {
  const [editor] = useLexicalComposerContext();
  const firstRender = useRef(true);

  useEffect(() => {
    if (!initialText || !firstRender.current) return;

    editor.update(() => {
      firstRender.current = false;
      const markdown = $convertFromMarkdownString(initialText, TRANSFORMERS);
      return markdown;
    });
  }, [initialText, editor]);

  useEffect(() => {
    return editor.registerUpdateListener(() => {
      editor.update(() => {
        const markdownString = $convertToMarkdownString(TRANSFORMERS);
        onChange(markdownString);
      });
    });
  }, [editor, onChange]);

  return null;
};
