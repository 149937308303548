import { Filter, FilterProps } from '@unique/component-library';

interface ListControlFiltersProps {
  filterOptions: FilterProps[];
  onFilterUpdate: (filterId: string, selectedOption?: string | string[]) => void;
}

export const ListControlFilters = ({ filterOptions, onFilterUpdate }: ListControlFiltersProps) => {
  return (
    <>
      <div className="text-on-background-dimmed mb-5 whitespace-nowrap text-xs font-semibold uppercase tracking-[1.5px] md:mb-0">
        Filter by:
      </div>
      {filterOptions.map((filter) => (
        <Filter
          key={filter.id}
          {...filter}
          showSearch={filter.showSearch}
          onUpdateFilter={(selectedOptions) => onFilterUpdate(filter.id, selectedOptions)}
          selectedOptions={filter.selectedOptions}
          buttonClasses="max-w-[200px]"
        />
      ))}
    </>
  );
};
