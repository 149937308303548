'use client';

import { FC, ReactNode, RefObject, createContext, useEffect, useRef, useState } from 'react';
import { ButtonIcon, ButtonVariant } from '@unique/component-library';
import { IconArrowUp } from '@unique/icons';
import cn from 'classnames';
const SCROLL_DURATION = 600;
interface scrollOptions {
  to?: number;
  behavior?: 'instant' | 'smooth';
  // If true will display a scrollToBottom button at the bottom of the scrollWrapper
  // only if the to HTMLElement height is higher than the scrollWrapper height
  displayScrollToBottomButton?: boolean;
  // Will scroll pass the top of the messaage
  scrollPassMessageTop?: boolean;
}

interface ScrollWrapperContextProps {
  scrollTo?: (options?: scrollOptions) => Promise<void>;
  scrollToBottom?: (options?: scrollOptions) => Promise<void>;
  setOnScrollEvent: (func?: (scrollPercent: number) => void) => void;
  scrollWrapperRef: RefObject<HTMLDivElement> | null;
  resetScrollLock: () => void;
}

export const ScrollWrapperContext = createContext<ScrollWrapperContextProps>({
  setOnScrollEvent: () => {},
  scrollWrapperRef: null,
  resetScrollLock: () => {},
});

interface ScrollWrapperProps {
  children: ReactNode;
  id?: string;
  className?: string;
}

/*
	ScrollWrapper is a component providing vertical scroll. Design for chat.
	- scollToBotton() smooth scroll (load chat or send prompt)
	- scrollToBottomCancelable() instant scroll and cancel if user scroll up (prompt update scenario)
	Methods are shared using the ScrollWrapperContext Provider.
*/
export const ScrollWrapper: FC<ScrollWrapperProps> = ({ id, className, children }) => {
  const ref = useRef<HTMLDivElement>(null);

  // Data are stored as refs to avoid re-render of the component
  // Scrolling events are handled outside of react lifecycle and connecting with those
  // was creating many issues with the scroll position
  const isScrollDisabledRef = useRef<boolean>(false);

  // Store a function to call on scroll event.
  // First param is scrollPercent value
  const [onScrollEvent, setOnScrollEvent] = useState<(scrollPercent: number) => void | null>();
  const [isScrollToBottomButtonVisible, setIsScrollToBottomButtonVisible] =
    useState<boolean>(false);

  // When a user scrolls, we disable the scroll. This function is used to reset the scroll lock.
  // Shoulc be trigger at the end of a stream
  const resetScrollLock = () => {
    isScrollDisabledRef.current = false;
  };

  /*
		Listen to scroll event to keep up to date isScrolling and isScrollDisabled
  */
  useEffect(() => {
    const scrollingElement: HTMLDivElement | null = ref.current;

    let ticking = false;
    // Track previous position to detect scroll direction in scrollListener
    let previousPosition: number = 0;

    // Scroll listener to detect if user is scrolling
    const scrollListener = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          // On scroll, ANY SCROLL, we set isScrolling to true
          if (scrollingElement) {
            const scrollTop = Math.ceil(scrollingElement.scrollTop);
            const scrollHeight = Math.ceil(scrollingElement.scrollHeight);

            if (isScrollToBottomButtonVisible) {
              setIsScrollToBottomButtonVisible(false);
            }

            onScrollEvent?.(scrollTop / scrollHeight);
            // Detect scrolling up to disable cancelable scrolling (eg. during prompt update)
            if (previousPosition > scrollTop && isScrollDisabledRef.current === false) {
              // Disable scrolling if user scroll up
              isScrollDisabledRef.current = true;
              // Trigger scrollTo again to stop current animation
              ref.current?.scrollTo({
                top: scrollTop,
                behavior: 'instant',
              });
            }
            previousPosition = scrollTop;
          }
          ticking = false;
        });
        ticking = true;
      }
    };
    scrollingElement?.addEventListener('scroll', scrollListener);
    
    return () => {
      scrollingElement?.removeEventListener('scroll', scrollListener);
    };
  }, [onScrollEvent, isScrollToBottomButtonVisible]);

  /**
   * Smooth scroll from any position to bottom. User has to wait until scroll ends
   * @param options - The options for the scroll.
   * @param options.behavior - The behavior of the scroll. (Default: instant)
   * @param options.behavior.instant - Instant scroll.
   * @param options.behavior.smooth - Smooth scroll.
   */
  const scrollToBottom = (options?: scrollOptions): Promise<void> => {
    scrollTo({
      ...options,
      to: ref.current?.scrollHeight,
      scrollPassMessageTop: true,
    });
    return Promise.resolve();
  };

  /**
   * Scroll to a specific position in pixel within scrollWrapper.
   * @param options - The options for the scroll.
   * @param options.to - The position to scroll to in pixels.
   * @param options.behavior - The behavior of the scroll. (Default: instant)
   * @param options.behavior.instant - Instant scroll.
   * @param options.behavior.smooth - Smooth scroll.
   * @param options.displayScrollToBottomButton - If true, will display a scrollToBottom button at the bottom of the scrollWrapper.
   */
  const lastScrollToBottomTimestamp = useRef(0);
  const timeoutScrollToBottom = useRef<NodeJS.Timeout>();
  const scrollTo = (options?: scrollOptions): Promise<void> => {
    if (!ref.current) {
      return Promise.resolve();
    }
    if (!options?.to) {
      console.error('No to position provided');
      return Promise.resolve();
    }

    if (ref.current && options.to < ref.current.scrollTop) {
      // ONly scrolling down
      return Promise.resolve();
    }

    if (ref.current?.scrollTop === options.to) {
      // If scroll to current location and dev asked for scrollToBottomButton, enable it
      // BUtton will only show if message height is higher than scrollWrapper height
      if (options?.displayScrollToBottomButton) {
        setIsScrollToBottomButtonVisible(true);
      }
      return Promise.resolve();
    }

    if (!options?.behavior || options?.behavior === 'instant') {
      // Instant scroll need no buffering because no animation.
      ref.current?.scrollTo({
        top: options.to,
        behavior: 'instant',
      });
      return Promise.resolve();
    }

    if (isScrollDisabledRef.current === true) {
      // If scroll is disabled, we don't scroll
      return Promise.resolve();
    }

    // We bufffer requests based on animation duration.
    if (new Date().getTime() - lastScrollToBottomTimestamp.current < SCROLL_DURATION) {
      if (!timeoutScrollToBottom.current) {
        timeoutScrollToBottom.current = setTimeout(() => {
          clearTimeout(timeoutScrollToBottom.current);
          timeoutScrollToBottom.current = undefined;
          scrollTo(options);
        }, new Date().getTime() - lastScrollToBottomTimestamp.current);
      }
      return Promise.resolve();
    }

    // Only set lastScrollToBottomTimestamp if smooth scroll is triggered
    lastScrollToBottomTimestamp.current = new Date().getTime();
    let toY = options?.to;

    // Total scroll available
    const totalScrollAvailable = ref.current.scrollHeight;
    // Screen height
    const screenHeight = ref.current.clientHeight;
    // Message height
    const messageHeight = totalScrollAvailable - toY;

    if (messageHeight < screenHeight && !options?.scrollPassMessageTop) {
      // If message height it smaller than the screenHeight we don't scroll to the message
      // but calculte the scroll position to scroll to the bottom of the message

      // Current scroll position
      const currentScrollPosition = ref.current.scrollTop;
      // Math.min make sure we never go lower than the top Message
      toY = Math.min(currentScrollPosition + messageHeight, options?.to);
    }
    // Using native Javascript scrollTo as react-scroll was having performance issues
    ref.current?.scrollTo({
      top: toY,
      behavior: 'smooth',
    });
    return Promise.resolve();
  };

  return (
    <ScrollWrapperContext.Provider
      value={{
        scrollTo,
        scrollToBottom,
        setOnScrollEvent: (func) => {
          // Set the function to call on scroll event
          setOnScrollEvent(() => (scrollPercent: number) => func?.(scrollPercent));
        },
        scrollWrapperRef: ref,
        resetScrollLock,
      }}
    >
      <div ref={ref} id={id} className={className}>
        {children}
      </div>

      {/* Scroll to bottom button, only if scroll is not at the top */}
      <div className="z-55 pointer-events-none absolute bottom-0 top-0 flex w-full items-end justify-center">
        <ButtonIcon
          className={cn('bg-background absolute mb-[160px] rotate-180 transition-all', {
            'pointer-events-auto bottom-0 opacity-100': isScrollToBottomButtonVisible,
            'pointer-events-none bottom-2 opacity-0': !isScrollToBottomButtonVisible,
          })}
          variant={ButtonVariant.TERTIARY}
          icon={<IconArrowUp width="20px" height="20px" />}
          onClick={() => {
            scrollToBottom({
              behavior: 'smooth',
            });
            isScrollDisabledRef.current = false;
            setIsScrollToBottomButtonVisible(false);
          }}
        ></ButtonIcon>
      </div>
    </ScrollWrapperContext.Provider>
  );
};
