export enum DateFilterOptions {
  Today = 'Today',
  Yesterday = 'Yesterday',
  ThisWeek = 'This week',
  LastWeek = 'Last week',
  ThisMonth = 'This month',
  PreviousMonth = 'Previous month',
  Last30Days = 'Last 30 days',
  Last3Months = 'Last 3 months',
  Last6Months = 'Last 6 months',
  PreviousYear = 'Previous year',
  OlderThanPreviousYear = 'Older than previous year',
}
