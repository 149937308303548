import { FC } from 'react';
import { IconProps } from '.';

export const IconFileSearch: FC<IconProps> = ({ width = '16px', height = '16px' }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="find_in_page_24dp_5F6368_FILL0_wght400_GRAD0_opsz24 1">
        <path
          id="Vector"
          d="M2.99992 16C2.54159 16 2.14922 15.8433 1.82284 15.53C1.49645 15.2167 1.33325 14.84 1.33325 14.4V1.6C1.33325 1.16 1.49645 0.783333 1.82284 0.47C2.14922 0.156667 2.54159 0 2.99992 0H9.72908C9.97908 0 10.2152 0.05 10.4374 0.15C10.6596 0.25 10.8471 0.393333 10.9999 0.58L14.2708 4.36C14.3958 4.50667 14.493 4.66667 14.5624 4.84C14.6319 5.01333 14.6666 5.19333 14.6666 5.38V14.4C14.6666 14.76 14.4964 15.0067 14.1562 15.14C13.8159 15.2733 13.5138 15.2133 13.2499 14.96L9.66658 11.56C9.43047 11.7067 9.17353 11.8167 8.89575 11.89C8.61797 11.9633 8.31936 12 7.99992 12C7.08325 12 6.29853 11.6867 5.64575 11.06C4.99297 10.4333 4.66658 9.68 4.66658 8.8C4.66658 7.92 4.99297 7.16667 5.64575 6.54C6.29853 5.91333 7.08325 5.6 7.99992 5.6C8.91658 5.6 9.70131 5.91333 10.3541 6.54C11.0069 7.16667 11.3333 7.92 11.3333 8.8C11.3333 9.10667 11.2951 9.39333 11.2187 9.66C11.1423 9.92667 11.0277 10.1733 10.8749 10.4L12.9999 12.48V5.36L9.70825 1.6H2.99992V14.4H9.93742C10.2152 14.4 10.4235 14.4833 10.5624 14.65C10.7013 14.8167 10.7708 15 10.7708 15.2C10.7708 15.4 10.7013 15.5833 10.5624 15.75C10.4235 15.9167 10.2152 16 9.93742 16H2.99992ZM7.99992 10.4C8.45825 10.4 8.85061 10.2433 9.177 9.93C9.50339 9.61667 9.66658 9.24 9.66658 8.8C9.66658 8.36 9.50339 7.98333 9.177 7.67C8.85061 7.35667 8.45825 7.2 7.99992 7.2C7.54158 7.2 7.14922 7.35667 6.82283 7.67C6.49645 7.98333 6.33325 8.36 6.33325 8.8C6.33325 9.24 6.49645 9.61667 6.82283 9.93C7.14922 10.2433 7.54158 10.4 7.99992 10.4Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
