import { ButtonIcon, ButtonVariant, Portal } from '@unique/component-library';
import { ReactNode, useEffect, useState } from 'react';
import cn from 'classnames';
import { IconCaretDown, IconClose } from '@unique/icons';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  header?: ReactNode;
};

enum TrayState {
  OPEN,
  CLOSED,
  MINIMIZED,
}

export function SystemTrayToastNotification({ children, header, isOpen, onClose }: Props) {
  const [trayState, setTrayState] = useState<TrayState>(TrayState.CLOSED);

  const handleClose = () => {
    setTrayState(TrayState.CLOSED);
    onClose();
  };

  useEffect(() => {
    setTrayState(isOpen ? TrayState.OPEN : TrayState.CLOSED);
  }, [isOpen]);

  return (
    <Portal id="upload-system-tray-notification">
      <div
        className={cn({
          'bg-control shadow-light fixed bottom-0 right-5 z-50 w-96 overflow-hidden rounded-t-lg transition-all':
            true,
          hidden: trayState === TrayState.CLOSED,
          'h-14': trayState === TrayState.MINIMIZED,
          'h-auto': trayState === TrayState.OPEN,
        })}
      >
        <div className="bg-background flex h-14 items-center justify-between px-5">
          <div>{header}</div>
          <div className="flex items-center">
            {trayState === TrayState.OPEN && (
              <ButtonIcon
                onClick={() => setTrayState(TrayState.MINIMIZED)}
                className="hover:!text-on-background-dimmed text-on-background-main !px-0"
                variant={ButtonVariant.SHAPE}
              >
                <IconCaretDown />
              </ButtonIcon>
            )}
            {trayState === TrayState.MINIMIZED && (
              <ButtonIcon
                onClick={() => setTrayState(TrayState.OPEN)}
                className="hover:!text-on-background-dimmed text-on-background-main rotate-180 !px-0"
                variant={ButtonVariant.SHAPE}
              >
                <IconCaretDown />
              </ButtonIcon>
            )}
            <ButtonIcon
              onClick={handleClose}
              className="hover:!text-on-background-dimmed text-on-background-main !px-0"
              variant={ButtonVariant.SHAPE}
            >
              <IconClose />
            </ButtonIcon>
          </div>
        </div>
        <div className="max-h-[90vh] overflow-y-auto">{children}</div>
      </div>
    </Portal>
  );
}
