import {
  subDays,
  subWeeks,
  subMonths,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subYears,
  startOfYear,
  endOfYear,
  startOfDay,
  endOfDay,
  format,
  isSameDay,
} from 'date-fns';
import { DateFilterOptions } from '../../enums/dateFilterOptions';

/**
 * Take a DateFilterOptions value and return a tuple of two dates that represent the start and end of the range.
 * @param range
 * @param date (optional) Default to current date
 * @returns
 */
export const getDateRange = (range: DateFilterOptions, date: Date = new Date()): [Date, Date] => {
  let dateStart: Date = new Date(0);
  let dateEnd: Date = new Date();
  switch (range) {
    case DateFilterOptions.Today:
      dateStart = startOfDay(date);
      dateEnd = endOfDay(date);
      break;
    case DateFilterOptions.Yesterday:
      dateStart = startOfDay(subDays(date, 1));
      dateEnd = endOfDay(dateStart);
      break;
    case DateFilterOptions.ThisWeek:
      // 1 = Monday
      dateStart = startOfWeek(date, { weekStartsOn: 1 });
      dateEnd = endOfWeek(dateStart, { weekStartsOn: 1 });
      break;
    case DateFilterOptions.LastWeek:
      // 1 = Monday
      dateStart = startOfWeek(subWeeks(date, 1), { weekStartsOn: 1 });
      dateEnd = endOfWeek(dateStart, { weekStartsOn: 1 });
      break;
    case DateFilterOptions.ThisMonth:
      dateStart = startOfMonth(new Date());
      dateEnd = endOfMonth(dateStart);
      break;
    case DateFilterOptions.PreviousMonth:
      dateStart = startOfMonth(subMonths(date, 1));
      dateEnd = endOfMonth(dateStart);
      break;
    case DateFilterOptions.Last30Days:
      dateStart = subDays(date, 30);
      dateEnd = date;
      break;
    case DateFilterOptions.Last3Months:
      dateStart = subMonths(date, 3);
      dateEnd = date;
      break;
    case DateFilterOptions.Last6Months:
      dateStart = subMonths(date, 6);
      dateEnd = date;
      break;
    case DateFilterOptions.PreviousYear:
      dateStart = startOfYear(subYears(date, 1));
      dateEnd = endOfYear(subYears(date, 1));
      break;
    case DateFilterOptions.OlderThanPreviousYear:
      dateEnd = endOfYear(subYears(date, 2));
      break;
  }
  return [dateStart, dateEnd];
};

/**
 * Display the date range as a string for tooltip
 * @param range (DateFilterOptions)
 * @returns
 */
export const dateRangeToString = (range: DateFilterOptions) => {
  const [start, end] = getDateRange(range);
  const formatString = 'MMM d, yyyy';
  // if start older than 1970, return before end
  if (isSameDay(start, new Date(0))) {
    return `Before ${format(end, formatString)}`;
  } else if (isSameDay(start, new Date())) {
    return `After ${format(start, formatString)}`;
  } else {
    return `${format(start, formatString)} to ${format(end, formatString)}`;
  }
};
