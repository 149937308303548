'use client';
import { FC, useState, useMemo, useRef, useEffect } from 'react';
import { IconArrowDown, IconSync } from '@unique/icons';

type ScrollPaginationProps = {
  counter?: number;
  isLoading: boolean;
  enableInfiniteScroll?: boolean;
  onClick: () => void;
};

export const ScrollPagination: FC<ScrollPaginationProps> = ({
  counter,
  isLoading,
  enableInfiniteScroll,
  onClick,
}) => {
  const ref = useRef<HTMLButtonElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  // Prevents multiple triggers of handleClick when component remains visible
  // This ref persists across re-renders and helps debounce infinite scroll calls
  const hasTriggeredRef = useRef(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      }),
    [],
  );

  useEffect(() => {
    if (enableInfiniteScroll && ref.current) {
      observer.observe(ref.current);
    }
    return () => observer.disconnect();
  }, [enableInfiniteScroll, observer]);

  useEffect(() => {
    if (!isVisible) {
      hasTriggeredRef.current = false;
    }
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && enableInfiniteScroll && !isLoading && !hasTriggeredRef.current) {
      hasTriggeredRef.current = true;
      onClick();
    }
  }, [isVisible, isLoading, enableInfiniteScroll, onClick]);

  return (
    <>
      <button
        ref={ref}
        disabled={isLoading}
        onClick={onClick}
        className="hover:bg-background my-1 flex h-[48px] w-full cursor-pointer items-center justify-center gap-[14px] pr-[12px] text-sm"
      >
        {isLoading ? (
          <>
            <div className="animate-reverse-spin text-on-background-main shrink-0">
              <IconSync width="18px" />
            </div>
          </>
        ) : (
          <>
            <div className="text-on-background-main shrink-0">
              <IconArrowDown width="18px" />
            </div>
          </>
        )}
        <div className="text-on-background-main text-sm">
          {counter ? `${counter} more to load` : 'loading more'}
        </div>
      </button>
    </>
  );
};
