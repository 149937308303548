'use client';

import * as Sentry from '@sentry/nextjs';
import { logger } from '@unique/next-commons/logger';
import {
  ErrorPage,
  ForbiddenPage,
  InternalServerErrorPage,
  NotFoundPage,
  NotImplementedPage,
  ToastVariant,
  TooManyRequestsPage,
  useToast,
} from '@unique/shared-library';
import { SignoutRedirectArgs } from 'oidc-client-ts';
import { FC, PropsWithChildren, createContext, useState } from 'react';
import { SWRConfig, useSWRConfig } from 'swr';
import { isNetworkError } from '../helpers/isNetworkError';

const errorHandlerLogger = logger.child({ package: 'errorHandler' });
interface ErrorHandlerProps {
  basePath?: string;
  selfUrl?: string;
  suppress404IfSelfUrl?: boolean;
}

interface Error {
  message: string;
  status?: number;
  canRetry?: boolean;
}

interface ErrorHandlerContextProps {
  setError: (error: Error) => void;
  setLogout: (logout?: (args?: SignoutRedirectArgs) => void) => void;
}

export const ErrorHandlerContext = createContext<ErrorHandlerContextProps>({
  setError: () => {
    throw new Error('Called outside of the context.');
  },
  setLogout: () => {
    throw new Error('Called outside of the context.');
  },
});

const maxRetries = 10; // Maximum number of retries

export const ErrorHandler: FC<PropsWithChildren<ErrorHandlerProps>> = ({
  children,
  basePath,
  selfUrl,
  suppress404IfSelfUrl,
}) => {
  const { showToast } = useToast();
  const [error, setError] = useState<Error>();
  const [logout, setLogout] = useState<((args?: SignoutRedirectArgs) => void) | undefined>(
    undefined,
  );
  const [isRetryingRequest, setIsRetryingRequest] = useState<boolean>(false);
  const { onErrorRetry } = useSWRConfig();

  const currenturl = typeof window !== 'undefined' && window.location.href;

  const getErrorPage = () => {
    switch (error?.status) {
      case 403:
        return <ForbiddenPage logout={logout} selfUrl={selfUrl} />;
      case 404:
        return <NotFoundPage basePath={basePath} />;
      case 429:
        return <TooManyRequestsPage />;
      case 500:
        return <InternalServerErrorPage basePath={basePath} />;
      case 501:
        return <NotImplementedPage basePath={basePath} />;
      default:
        return <ErrorPage basePath={basePath} error={error} />;
    }
  };

  const isScopeError = (err: { message: string }) =>
    err?.message?.includes('Could not fetch Scopes');

  // If it's a scope or network error, we want to retry the request
  // Why for a scope error? We are running only one instance of the scope management app,
  // so it can happen that there is a downtime when the app is restarting
  const isErrorTypeForRetry = (err: { message: string }) => {
    return isScopeError(err) || isNetworkError(err);
  };

  const isMutationOrSubscription = (key: string) =>
    key?.includes('operation:"mutation"') || key?.includes('operation:"subscription"');

  return (
    <ErrorHandlerContext.Provider value={{ setError, setLogout }}>
      <SWRConfig
        value={{
          onErrorRetry: (err, key, config, revalidate, revalidateOpts) => {
            // dont retry mutations or subscriptions
            if (isMutationOrSubscription(key)) return;
            const { retryCount } = revalidateOpts;
            // If it's the first retry and we are already retrying, we don't want to retry again
            if (retryCount === 1 && isRetryingRequest) return;
            errorHandlerLogger.info(`Handle error retry with retryCount ${retryCount}`);
            if (!isErrorTypeForRetry(err)) return;
            setIsRetryingRequest(true);
            if (retryCount > maxRetries) {
              errorHandlerLogger.info(`Max retries reached for key ${key}`);
              const rawError = {
                message: `Max retries reached for key ${key} - Error: ${err}`,
              };
              Sentry.captureException(rawError);
              showToast?.({
                message: 'Unable to complete request. Please try again later.',
                variant: ToastVariant.ERROR,
                duration: Infinity,
              });
              return;
            }
            onErrorRetry(err, key, config, revalidate, revalidateOpts);
          },
          onError: async (result) => {
            Sentry.captureException(result);
            // Errors that should be retried are handled in the onErrorRetry callback
            if (isErrorTypeForRetry(result)) {
              errorHandlerLogger.error(`Handle error in onErrorRetry: ${result.toString()}`);
              return;
            }
            errorHandlerLogger.error(`Handle error: ${JSON.stringify(result)}`);
            try {
              // If there is no error extension, then it's not a nested GraphQL error, so we display the generic error page
              const firstErrorExtension = result?.response?.errors[0]?.extensions;
              if (!firstErrorExtension) {
                const rawError = {
                  message: `Error encountered - ${result}`,
                };
                setError(rawError);
                console.error(JSON.stringify(rawError));
                return;
              }
              const rawError = firstErrorExtension.exception ?? firstErrorExtension.response;
              console.error('Raw error ', firstErrorExtension);

              const message = rawError.message;
              const status = rawError.statusCode ?? rawError.status;

              const errorToHandle = {
                message,
                status,
              };
              switch (status) {
                case 400:
                  showToast?.({
                    message,
                    variant: ToastVariant.ERROR,
                  });
                  break;
                case 404:
                  if (suppress404IfSelfUrl && currenturl === selfUrl) break;
                  if (currenturl === selfUrl) {
                    showToast?.({
                      message,
                      variant: ToastVariant.ERROR,
                    });
                  } else {
                    setError(errorToHandle);
                  }
                  break;
                default:
                  setError(errorToHandle);
              }
            } catch (err) {
              errorHandlerLogger.error(`Error handler failed with: ${err}`);
            }
          },
          errorRetryInterval: 1000,
        }}
      >
        {error ? getErrorPage() : children}
      </SWRConfig>
    </ErrorHandlerContext.Provider>
  );
};
