import { ReactNode } from 'react';
import { generateExcelHeaders } from './generateExcelHeaders';

export const generateEmptyLoadingRows = (
  columnCount = 26,
  loadingComponent: string | ReactNode,
) => {
  const headersGenerated: string[] = [];
  const columns = generateExcelHeaders(columnCount).map((header) => {
    headersGenerated.push(header);
    return {
      headerName: header,
      headerClass: '',
      field: header,
      sortable: false,
      minWidth: 200,
      width: 200,
      editable: false,
      cellRenderer: () => loadingComponent,
    };
  });

  const emptyRows = headersGenerated.map((header, index) => {
    return {
      field: header,
      id: (index + 1).toString(),
    };
  });

  return { columns: columns, rows: emptyRows };
};
