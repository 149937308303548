import { useCallback, useEffect, useMemo, useState } from 'react';

import type { IDoesFilterPassParams } from 'ag-grid-enterprise';
import type { CustomFilterProps } from 'ag-grid-react';
import { extractFileNameFromReference, handleAfterGuiDetached } from '../helpers';
import { CustomMagicTableFilter } from './CustomMagicTableFilter';

export const ReferenceFilter = ({
  model,
  onModelChange,
  getValue,
  api,
  colDef,
}: CustomFilterProps) => {
  const [checkedReferences, setCheckedReferences] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams) => {
      // Ignore the pinned row
      if (node.id === '1') return true;

      const value = extractFileNameFromReference(getValue(node).toString()) || '';

      const result = model!.value.split('~').includes(value);

      return result;
    },
    [model],
  );

  const references = useMemo(() => {
    const rowData = new Set<string>();
    api.forEachNode((node) => {
      if (!node?.data) return;
      const reference = extractFileNameFromReference(
        node.data[colDef.field as keyof typeof node.data],
      );
      if (!reference) return;
      rowData.add(reference);
    });
    return Array.from(rowData);
  }, [api, colDef]);

  const afterGuiDetached = useCallback(() => {
    handleAfterGuiDetached(api, colDef, references, checkedReferences);
  }, [api, checkedReferences, references, colDef]);

  useEffect(() => {
    setCheckedReferences(references);
  }, [references]);

  const handleChange = useCallback(
    (reference: string) => {
      setCheckedReferences((prevRefs) => {
        const newReferences = prevRefs.includes(reference)
          ? prevRefs.filter((ref) => ref !== reference)
          : [...prevRefs, reference];

        onModelChange({
          value: newReferences.join('~'),
        });

        return newReferences;
      });
    },
    [onModelChange, setCheckedReferences],
  );

  const handleSelectAll = (newSelected: boolean) => {
    const newReferences = newSelected ? references : [];
    setCheckedReferences(newReferences);
    onModelChange({
      value: newSelected ? references.join('~') : '',
    });
  };

  return (
    <CustomMagicTableFilter
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
      onSelectAll={handleSelectAll}
      filterList={references}
      checkedFilters={checkedReferences}
      onChange={handleChange}
      onFilterPass={doesFilterPass}
      onAfterGuiDetached={afterGuiDetached}
    />
  );
};
