import { Dispatch, SetStateAction, useState } from 'react';

interface BulkSelectWithShiftProps<
  T extends { id: string | number },
  U extends Record<number, boolean> | Array<string | number> = Array<string | number>,
> {
  items: T[];
  selectedItems: U;
  setSelectedItems: Dispatch<SetStateAction<U>>;
}

export const useBulkSelectWithShift = <
  T extends { id: string | number },
  U extends Record<number, boolean> | Array<string | number> = Array<string | number>,
>({
  items,
  selectedItems,
  setSelectedItems,
}: BulkSelectWithShiftProps<T, U>) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);

  const toggleItem = (itemId: string | number, event?: React.MouseEvent) => {
    const newSelectedItemIndex = items?.findIndex((item) => item.id === itemId);

    // If Shift key is pressed we add to selected items all items between the last selected item and the current item
    if (event?.shiftKey) {
      // select all items between the last selected item and the current item
      const start = Math.min(selectedItemIndex, newSelectedItemIndex);
      const end = Math.max(selectedItemIndex, newSelectedItemIndex);

      if (Array.isArray(selectedItems)) {
        const itemsToSelect = items?.slice(start, end + 1);
        setSelectedItems(
          (prevItems) =>
            Array.from(
              new Set([
                ...(prevItems as Array<string | number>),
                ...itemsToSelect.map((item) => item.id),
              ]),
            ) as U,
        );
      } else {
        const newSelectedItems = { ...selectedItems };
        for (let i = start; i <= end; i++) {
          newSelectedItems[i] = true;
        }
        setSelectedItems(newSelectedItems);
      }

      return;
    }

    // Update startPoint for multiple selection
    setSelectedItemIndex(newSelectedItemIndex);

    if (Array.isArray(selectedItems)) {
      if (selectedItems.includes(itemId)) {
        setSelectedItems(
          (selectedItems) =>
            (selectedItems as unknown as Array<string | number>).filter(
              (selectedItem: string | number) => selectedItem !== itemId,
            ) as U,
        );
        return;
      }
    } else {
      if (selectedItems[newSelectedItemIndex]) {
        const newSelectedItems = { ...selectedItems };
        delete newSelectedItems[newSelectedItemIndex];
        setSelectedItems(newSelectedItems);
        return;
      }
    }

    if (Array.isArray(selectedItems)) {
      setSelectedItems(
        (selectedItems) => [...(selectedItems as Array<string | number>), itemId] as U,
      );
    } else {
      setSelectedItems({ ...selectedItems, [newSelectedItemIndex]: true });
    }
  };

  return { toggleItem };
};
