'use client';
import { useState, useEffect } from 'react';

export const useIsTouchDevice = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    // Create media query to check for touch input ('coarse' pointer = finger/touch input)
    const mediaQuery = window.matchMedia('(pointer: coarse)');
    setIsTouchDevice(mediaQuery.matches);

    // Add listener that fires whenever the device's input capability changes
    // e.matches will be:
    // - true when switching TO touch input
    // - false when switching FROM touch input
    mediaQuery.addEventListener('change', (e) => setIsTouchDevice(e.matches));
    return () => mediaQuery.removeEventListener('change', (e) => setIsTouchDevice(e.matches));
  }, []);

  return isTouchDevice;
};
