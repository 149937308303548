import cn from 'classnames';
import { useScreens } from '@unique/shared-library';
import { FC, ReactNode, memo, useState } from 'react';
import { IconCaretUp, IconCheckmark } from '@unique/icons';

interface Step {
  title: string;
  component: ReactNode;
}

interface StepperProps {
  steps: Step[];
  currentStep: number;
  headerClassName?: string;
}

interface StepperAccordionProps {
  title: ReactNode;
  children: ReactNode;
  className?: string;
}

export const StepperAccordion: FC<StepperAccordionProps> = ({ children, title, className }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={cn('border-primary-cta rounded-lg border bg-white shadow-md', className)}>
      <div className="p-6 pb-8">
        <button
          className="border-secondary-variant flex w-full items-center justify-between font-semibold"
          onClick={handleClick}
        >
          <h2 className="text-xl font-bold">{title}</h2>
          <span
            className={cn('ml-1.5 origin-center transition', { '-rotate-180 transform': !isOpen })}
          >
            <IconCaretUp />
          </span>
        </button>
        <div
          className={cn('transition-all duration-300 ease-in-out', {
            'max-h-[1000px] opacity-100': isOpen,
            'pointer-events-none max-h-0 overflow-hidden opacity-0': !isOpen,
          })}
        >
          <div className="pt-8">{children}</div>
        </div>
      </div>
    </div>
  );
};

const StepItem = memo(
  ({
    stepId,
    step,
    isLast,
    currentStep,
  }: {
    step: Step;
    stepId: number;
    isLast: boolean;
    currentStep: number;
  }) => {
    const { isDesktop } = useScreens();

    const getBgColor = (stepId: number) =>
      stepId <= currentStep ? 'primary-cta' : 'background-variant';

    const getLineColor = (stepId: number) =>
      stepId < currentStep ? 'secondary' : 'background-variant';

    return (
      <>
        <li
          key={stepId}
          aria-current={stepId === currentStep ? 'step' : undefined}
          className={cn({
            'flex items-center justify-center gap-x-3 md:gap-x-3.5': true,
            'gap-0': isLast,
          })}
        >
          {stepId < currentStep && isDesktop ? (
            <span className="bg-primary-cta hover:bg-primary-cta text-on-primary relative flex h-7 w-7 items-center justify-center rounded-md">
              <IconCheckmark aria-hidden="true" className="h-2.5 w-2.5" />
            </span>
          ) : (
            <span
              className={`bg-${getBgColor(stepId)} border-${getBgColor(stepId)} relative flex h-7 w-7 items-center justify-center rounded-[4px] border-2`}
            >
              <span
                aria-hidden="true"
                className={`group-hover:bg-${getBgColor(stepId)} text-on-primary subtitle-2`}
              >
                {stepId}
              </span>
            </span>
          )}
          <span
            className={cn({
              'subtitle-2': true,
              'hidden sm:!block': currentStep !== stepId,
              'text-on-control-dimmed': currentStep < stepId,
              '!text-on-background-main': currentStep >= stepId,
            })}
          >
            {step.title}
          </span>
        </li>
        {!isLast && (
          <div
            className={`bg-${getLineColor(stepId)} h-px w-4 max-w-full flex-1 sm:w-8 lg:w-16 lg:max-w-16`}
          />
        )}
      </>
    );
  },
);

export const Stepper: FC<StepperProps> = ({ steps, currentStep, headerClassName }) => {
  if (currentStep < 1 || currentStep > steps.length) {
    throw new Error(`currentStep must be between 1 and ${steps.length}`);
  }

  return (
    <>
      <div className={cn('flex items-center justify-center py-5 md:mb-5', headerClassName)}>
        <nav
          aria-label="Progress"
          className="w-full max-w-[928px] justify-center overflow-auto [-ms-overflow-style:none] [scrollbar-width:none] lg:flex lg:max-w-full lg:overflow-visible [&::-webkit-scrollbar]:hidden"
        >
          <ol
            role="list"
            className="flex w-full min-w-max items-center justify-between gap-x-3 md:gap-x-5 lg:justify-center"
          >
            {steps.map((step, stepIdx) => {
              const stepId = stepIdx + 1;

              return (
                <StepItem
                  step={step}
                  key={stepId}
                  stepId={stepId}
                  currentStep={currentStep}
                  isLast={stepId === steps.length}
                />
              );
            })}
          </ol>
        </nav>
      </div>
      <div className="mx-auto max-w-[928px] py-6">{steps[currentStep - 1].component}</div>
    </>
  );
};
