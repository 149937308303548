import { getExcelColumnName } from './getExcelColumnName';

export function generateExcelHeaders(columnCount: number): string[] {
  const headers: string[] = [];

  for (let i = 0; i < columnCount; i++) {
    headers.push(getExcelColumnName(i));
  }

  return headers;
}
