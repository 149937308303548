import { Drawer, FilterProps } from '@unique/component-library';
import { FC } from 'react';
import { IconFilter } from '@unique/icons';
import { ListControlFilters } from './ListControlFilters';
import { useState } from 'react';
import Badge from '@unique/component-library/src/Badge';

interface ListControlFiltersMobileProps {
  numberOfFiltersApplied: number;
  onFilterUpdate: (filterId: string, selectedOption?: string | string[]) => void;
  filterOptions: FilterProps[];
}

export const ListControlFiltersMobile: FC<ListControlFiltersMobileProps> = ({
  filterOptions,
  onFilterUpdate,
  numberOfFiltersApplied,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <div
        className="text-on-background-dimmed relative flex cursor-pointer items-center md:hidden"
        onClick={() => setIsDrawerOpen(true)}
      >
        <IconFilter width="18px" height="18px" />
        {numberOfFiltersApplied > 0 && (
          <div className="absolute bottom-3 left-3">
            <Badge variant="error" size="small">
              {numberOfFiltersApplied}
            </Badge>
          </div>
        )}
      </div>
      <Drawer open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)}>
        <div className="mt-8 px-6">
          <ListControlFilters filterOptions={filterOptions} onFilterUpdate={onFilterUpdate} />
        </div>
      </Drawer>
    </>
  );
};
