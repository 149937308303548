import { useState } from 'react';
import IndeterminateCheckbox from '../../IndeterminateCheckbox';

export const FilterCheckbox = ({
  filterItem,
  isChecked,
  onChange,
}: {
  filterItem: string;
  isChecked: boolean;
  onChange: (filterItem: string) => void;
}) => {
  const [checked, setChecked] = useState(isChecked);

  const handleChange = () => {
    setChecked(!checked);
    onChange(filterItem);
  };

  return (
    <div
      key={filterItem}
      className="text-on-surface bg-surface flex items-center gap-2 p-1"
      onClick={handleChange}
    >
      <IndeterminateCheckbox checked={isChecked} readOnly />
      {filterItem}
    </div>
  );
};
