'use client';

import {
  Breadcrumbs,
  ButtonIcon,
  ColumnError,
  ColumnItemProps,
  Modal,
} from '@unique/component-library';
import { logger } from '@unique/next-commons/logger';
import { getColumnItemType } from '@unique/shared-library';
import { createContext, FC, useCallback, useEffect, useRef, useState } from 'react';
import { useFolderTreeQuery } from '../hooks/swr/useFolderTreeQuery';
import { FolderTreeQueryVariables } from '../@generated/graphql';
import { Folder } from '../hooks/types';
import { FOLDER_ID_PATH_PREFIX } from '../constants/folderIdPathPrefix';
import { FolderPathSelectionColumn } from './FolderPathSelectionColumn';
import Badge from '@unique/component-library/src/Badge';

type FolderPathSelectionSubmitType = {
  folderIdPath?: string;
  folderPathAsText?: string;
  folderIds?: string[];
  fileIds?: string[];
};

type FolderPathSelectionProps = {
  handleClose: () => void;
  handleSubmit: (e: FolderPathSelectionSubmitType) => void;
  submitButtonText: string;
  modalTitle: string;
  isSelectable?: boolean;
  maxItemsToSelect?: number;
  previouslySelectedFileIds?: string[];
};

type Column = {
  folders: Folder[];
  selectedFolderId?: string;
  selectedFolderName?: string;
  isLoading?: boolean;
  fileCount?: number;
};

const folderPathSelectionLogger = logger.child({
  package: 'admin',
  namespace: 'space:form:modal:folderPathSelection',
});

export type FolderPathSelectionContextType = {
  isSelectable: boolean;
  folderIdToRemove: string;
  setFolderIdToRemove: React.Dispatch<React.SetStateAction<string>>;
  folderIdToAdd: string;
  setFolderIdToAdd: React.Dispatch<React.SetStateAction<string>>;
  maxItemsToSelect?: number;
  selectedFileIds: string[];
  setSelectedFileIds: React.Dispatch<React.SetStateAction<string[]>>;
  selectedFolderIds: string[];
  setSelectedFolderIds: React.Dispatch<React.SetStateAction<string[]>>;
  previouslySelectedFileIds?: string[];
};

export const FolderPathSelectionContext = createContext<FolderPathSelectionContextType | null>(
  null,
);

export const FolderPathSelection: FC<FolderPathSelectionProps> = ({
  handleClose,
  handleSubmit,
  isSelectable = false,
  submitButtonText,
  modalTitle,
  maxItemsToSelect = Number.MAX_SAFE_INTEGER,
  previouslySelectedFileIds,
}) => {
  const [columns, setColumns] = useState<Column[]>([]);

  const [selectedFileIds, setSelectedFileIds] = useState<string[]>([]);
  const [selectedFolderIds, setSelectedFolderIds] = useState<string[]>([]);
  const [folderIdToRemove, setFolderIdToRemove] = useState<string>('');
  const [folderIdToAdd, setFolderIdToAdd] = useState<string>('');

  const [folderTreeQueryVariables, setFolderTreeQueryVariables] =
    useState<FolderTreeQueryVariables>({ parentLevels: 5 });

  const {
    data: folders,
    error: foldersErrors,
    mutate,
  } = useFolderTreeQuery(folderTreeQueryVariables);

  const columnsWrapper = useRef<HTMLDivElement>(null);

  const handleClickFolder = useCallback(
    (folder: Folder) => {
      // if folder is already selected and we have isSelectable set to true, dont to anything
      const isFolderAlreadySelected = columns.some((col) => col.selectedFolderId === folder.id);
      if (isSelectable && isFolderAlreadySelected) {
        return;
      }

      const newColumns = columns.map((column) => {
        const containsFolderId = column.folders.find((f) => f.id === folder.id);
        return containsFolderId
          ? { ...column, selectedFolderId: folder.id, selectedFolderName: folder.name }
          : column;
      });
      setColumns(newColumns);
      setFolderTreeQueryVariables((prev) => ({ ...prev, folderId: folder.id }));
    },
    [columns],
  );

  const mapFolderToColumnItemProps = useCallback(
    (folder: Folder): ColumnItemProps => {
      const isSelected = columns.some((column) => column.selectedFolderId === folder.id);
      return {
        label: folder.name,
        hasCaret: true,
        isSelected,
        type: getColumnItemType(folder.externalId, true),
        handleClick: () => handleClickFolder(folder),
        columnItemId: folder.id,
      };
    },
    [columns],
  );

  useEffect(() => {
    if (columns?.length > 3) {
      if (!columnsWrapper?.current) return;
      if (!columnsWrapper?.current.querySelector('#column-0')) return;
      // first column always exist, get column width based on that
      const firstColWidth = columnsWrapper?.current.querySelector('#column-0')?.clientWidth || 0;
      columnsWrapper.current.scrollTo({
        left: columnsWrapper.current.scrollLeft + firstColWidth,
        behavior: 'smooth',
      });
    }
  }, [columns]);

  useEffect(() => {
    if (!folders?.folderTree) return;
    const selectedFolderId = folderTreeQueryVariables.folderId;
    if (!selectedFolderId) {
      // set root folder column
      const rootFolders = folders.folderTree?.filter((folder) => !folder.parentId);
      setColumns([{ folders: rootFolders }]);
      return;
    }
    // set new folder column
    const newColumns = [...columns];
    const columnWithSelectedId = newColumns.findIndex(
      (column) => column.selectedFolderId === selectedFolderId,
    );

    newColumns[columnWithSelectedId + 1] = {
      folders: folders.folderTree.filter((f) => {
        if (f.parentId !== selectedFolderId) {
          return false;
        }
        return true;
      }),
    };
    newColumns.splice(columnWithSelectedId + 2);
    setColumns(newColumns);
  }, [folders]);

  const handleClickBreadcrumb = (selectedFolderId?: string) => {
    const columnWithSelectedId = columns.findIndex(
      (column) => column.selectedFolderId === selectedFolderId,
    );
    const newColumns = [...columns];
    newColumns.splice(columnWithSelectedId + 1);
    setColumns(newColumns);
    setFolderTreeQueryVariables((prev) => ({ ...prev, folderId: selectedFolderId }));
  };

  const handleCreate = () => {
    if (!isSelectable) {
      const columnsWithSelectedFolder = columns.filter(
        (column) => !!column.selectedFolderId && !!column.selectedFolderName,
      );
      const folderIdPath = columnsWithSelectedFolder
        .map((column) => column.selectedFolderId)
        .join('/');
      folderPathSelectionLogger.debug(`Submit folder path: ${folderIdPath}`);
      return handleSubmit({
        folderIdPath: `${FOLDER_ID_PATH_PREFIX}${folderIdPath}`,
        folderPathAsText: columnsWithSelectedFolder
          .map((column) => column.selectedFolderName)
          .join('/'),
      });
    }
    handleSubmit({ folderIds: selectedFolderIds, fileIds: selectedFileIds });
  };

  const handleSetFileCount = (columnIndex: number, count: number) => {
    const newColumns = [...columns];
    newColumns[columnIndex].fileCount = count;
    setColumns(newColumns);
  };

  const numberOfFilesSelected = selectedFileIds.length || 0;

  return (
    <Modal
      shouldShow
      handleClose={handleClose}
      key="manage-knowledge-scope-folder-path"
      wrapperClassNames="lg:!w-[830px] lg:!max-w-[830px]"
      classNames="px-0"
    >
      <div className="mb-6 flex items-center px-6">
        <div className="title-s text-on-background-main mr-4">{modalTitle} </div>
        {isSelectable && (
          <Badge variant="info">
            {numberOfFilesSelected} File{numberOfFilesSelected === 1 ? '' : 's'} selected
          </Badge>
        )}
      </div>
      {foldersErrors ? (
        <div className="flex flex-col items-start justify-start">
          <div className="bg-surface w-[260px] self-stretch pt-10">
            <ColumnError handleClick={() => mutate()} wrapperClasses="!pl-0 !pr-0" />
          </div>
        </div>
      ) : (
        <>
          <div className="mb-5 min-h-[18px] px-6">
            <Breadcrumbs
              items={[
                ...columns
                  .filter((column) => !!column.selectedFolderId)
                  .map((column, index) => {
                    const nextColumn = columns[index + 1];
                    const isLastColumn =
                      index === columns.filter((column) => !!column.selectedFolderId).length - 1;
                    return {
                      label: column.selectedFolderName,
                      id: column.selectedFolderId || '',
                      badgeText: isLastColumn
                        ? `${nextColumn?.folders?.length || 0} Folder${nextColumn?.folders?.length === 1 ? '' : 's'}, ${column.fileCount || 0} File${column.fileCount === 1 ? '' : 's'}`
                        : '',
                      onClick: () => handleClickBreadcrumb(column.selectedFolderId),
                    };
                  }),
              ]}
            />
          </div>
          <FolderPathSelectionContext.Provider
            value={{
              isSelectable,
              folderIdToRemove,
              setFolderIdToRemove,
              folderIdToAdd,
              setFolderIdToAdd,
              maxItemsToSelect,
              selectedFileIds,
              setSelectedFileIds,
              selectedFolderIds,
              setSelectedFolderIds,
              previouslySelectedFileIds,
            }}
          >
            <div className="overflow-x-auto overflow-y-hidden pb-5" ref={columnsWrapper}>
              <div className="relative flex min-h-[500px] w-full items-start gap-x-4">
                {columns.map((column, index) => {
                  const prevColumn = columns[index - 1];
                  return (
                    <div
                      className="bg-surface max-h-[500px] w-[33%] min-w-[260px] self-stretch overflow-y-auto"
                      id={`column-${index}`}
                      key={`column-${index}`}
                    >
                      <FolderPathSelectionColumn
                        folders={[
                          ...column.folders
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(mapFolderToColumnItemProps),
                        ]}
                        selectedFolderId={prevColumn?.selectedFolderId || ''}
                        handleSetFileCount={(count) =>
                          handleSetFileCount(index > 0 ? index - 1 : index, count)
                        }
                      />
                    </div>
                  );
                })}
                {columns?.length < 3 && (
                  <>
                    {Array(3 - columns.length)
                      .fill('')
                      .map((_, i) => (
                        <div
                          className="bg-surface w-[33%] min-w-[260px] self-stretch"
                          key={`skeleton-${i}`}
                        />
                      ))}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4 flex justify-end px-4">
              <ButtonIcon
                onClick={handleCreate}
                disabled={
                  (isSelectable && numberOfFilesSelected) === 0 ||
                  (!isSelectable && !columns[0]?.selectedFolderId)
                }
              >
                {submitButtonText}
              </ButtonIcon>
            </div>
          </FolderPathSelectionContext.Provider>
        </>
      )}
    </Modal>
  );
};
