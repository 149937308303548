import { useCallback, useState } from 'react';

import type { IDoesFilterPassParams } from 'ag-grid-enterprise';
import type { CustomFilterProps } from 'ag-grid-react';
import { CustomMagicTableFilter } from './CustomMagicTableFilter';
import { handleAfterGuiDetached } from '../helpers';

const hallucinations = ['LOW', 'MEDIUM', 'HIGH'];

export const HallucinationFilter = ({
  model,
  onModelChange,
  getValue,
  api,
  colDef,
}: CustomFilterProps) => {
  const [checkedHallucinations, setCheckedHallucinations] = useState<string[]>(hallucinations);

  const doesFilterPass = useCallback(
    ({ node }: IDoesFilterPassParams) => {
      if (node.id === '1') return true;
      if (!model?.value) return false;

      const value: string =
        getValue(node)
          .toString()
          .replace(/\*\*(.*?)\*\*/g, '$1') || '';

      const result = model!.value.split('~').some((res: string) => value.includes(res));

      return result;
    },
    [model],
  );

  const afterGuiDetached = useCallback(() => {
    handleAfterGuiDetached(api, colDef, hallucinations, checkedHallucinations);
  }, [api, checkedHallucinations, colDef, hallucinations]);

  const handleSelectAll = (isAllSelected: boolean) => {
    const newHallucinations = isAllSelected ? hallucinations : [];
    setCheckedHallucinations(newHallucinations);
    onModelChange({
      value: isAllSelected ? hallucinations.join('~') : '',
    });
  };

  const handleChange = useCallback(
    (hallucination: string) => {
      setCheckedHallucinations((prevRefs) => {
        const newHallucinations = prevRefs.includes(hallucination)
          ? prevRefs.filter((ref) => ref !== hallucination)
          : [...prevRefs, hallucination];

        onModelChange({
          value: newHallucinations.join('~'),
        });

        return newHallucinations;
      });
    },
    [onModelChange],
  );

  return (
    <CustomMagicTableFilter
      onSelectAll={handleSelectAll}
      filterList={hallucinations}
      checkedFilters={checkedHallucinations}
      onChange={handleChange}
      showSearch={false}
      onFilterPass={doesFilterPass}
      onAfterGuiDetached={afterGuiDetached}
    />
  );
};
